import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { MAP_SHAPES, MAP_EVENTS, GEO_JSON_IDS as IDS_CONST } from '@/config/map-config';
import L from 'leaflet';
import { debounce, isEqual } from 'lodash';
import { getIconWithText } from '@/utils/mapUtils';
import useSelectedPolygonDraw from '@/hooks/useSelectedPolygonDraw';
import useSelectedPolygonTabBinder from '@/useSelectedPolygonTabBinder';

const DEFAULT_MARKER = L.icon({
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  iconUrl: `/map/icons/default-icon.png`,
});

export default function useMapEvents() {
  const isDrawing = ref(false);

  const store = useStore();
  const isCoordsModalVisible = computed({
    get: () => store.state.map.isCoordinatesModalVisible,
    set: (value) => store.commit('map/setCoordinatesModalVisible', value),
  });

  const isTakeOffEditCoodrsModalVisible = computed({
    get: () => store.state.map.isTakeOffEditCoodrsModalVisible,
    set: (value) => store.commit('map/setTakeOffEditCoordinatesModalVisible', value),
  });

  const isLandingEditCoodrsModalVisible = computed({
    get: () => store.state.map.isLandingEditCoodrsModalVisible,
    set: (value) => store.commit('map/setLandingEditCoordinatesModalVisible', value),
  });

  const startDrawing = (shape, options = {}, isMission) => {
    store.commit('map/toggleSetSelectedPolygon');
    if (isMission) {
      window?.map?.pm?.enableDraw(shape, options);
    } else {
      if (shape === MAP_SHAPES.MARKER && !options?.markerStyle?.icon) {
        options.markerStyle = options.markerStyle
          ? { ...options.markerStyle, icon: DEFAULT_MARKER }
          : { icon: DEFAULT_MARKER };
      }
      if (
        store.state.map.drawCustomShape &&
        isEqual([shape, options], store.state.map.drawCustomShape)
      )
        return;
      store.commit('map/drawCustomShape', [shape, options]);
    }
  };

  const drawPolygon = (isMission = true) => {
    startDrawing(MAP_SHAPES.POLYGON, undefined, isMission);
  };

  const drawPolygonByLines = (isMission = true) => {
    startDrawing(MAP_SHAPES.POLYGON, undefined, isMission);
  };
  const drawLine = (isMission = true) => {
    startDrawing(MAP_SHAPES.LINE, undefined, isMission);
  };

  const drawCustomPointByCoords = (event) => {
    if (!event) return;
    const { lat: latitude, lon: longitude } = event;
    const marker = L.marker([latitude, longitude]); //.addTo(window.map);
    store.commit('map/saveCustomShape', {
      features: [marker.toGeoJSON()].map((e) => ({ ...e, id: IDS_CONST.custom })),
    });
    isCoordsModalVisible.value = false;
  };

  const onEditTakeOffCoords = (event) => {
    if (!event) return;
    const { lat: latitude, lon: longitude } = event;
    store.commit('params/setStart', [latitude, longitude]);
    isTakeOffEditCoodrsModalVisible.value = false;
  };

  const onEditLandingCoords = (event) => {
    if (!event) return;
    const { lat: latitude, lon: longitude } = event;
    store.commit('params/setFinish', [latitude, longitude]);
    isLandingEditCoodrsModalVisible.value = false;
  };

  const setVisibleCoordsModal = (visible) => {
    isCoordsModalVisible.value = visible;
  };
  const setTakeOffVisibleCoordsModal = (visible) => {
    isTakeOffEditCoodrsModalVisible.value = visible;
  };
  const setLandingVisibleCoordsModal = (visible) => {
    isLandingEditCoodrsModalVisible.value = visible;
  };
  const drawPoint = debounce((isMission = true) => {
    let icon;
    if (!isMission) {
      const index = store.getters['map/nextCustomIndex'];
      icon = getIconWithText(index, '/map/icons/yellow-marker.svg');
    }
    startDrawing(
      MAP_SHAPES.MARKER,
      {
        finishOn: 'click',
        markerStyle: { ...(icon ? { icon } : {}) },
      },
      isMission
    );

    window?.map?.once(MAP_EVENTS.ACTIONS.CREATE, handleDrawPoint, { isHandleDrawPoint: true });
  }, 500);
  const handleDrawPoint = (e) => {
    if (e.shape === MAP_SHAPES.MARKER) {
      console.log('Here', e.layer.toGeoJSON());
      store.commit('map/saveCustomShape', {
        features: [e.layer.toGeoJSON()].map((e) => ({ ...e, id: IDS_CONST.custom })),
      });

      e?.layer?.removeFrom(window.map);
      e.target.removeLayer(e.layer);
    }
  };

  //! lost reference to handleDrawPoint so .off method doesn't work here
  const clearPrevMapEvents = () => {
    window.map._events[MAP_EVENTS.ACTIONS.CREATE] = (
      window.map._events[MAP_EVENTS.ACTIONS.CREATE] ?? []
    ).filter(({ ctx }) => !ctx?.isHandleDrawPoint);
  };

  const drawStartFinish = (isStart = true) => {
    store.commit('map/toggleSetSelectedPolygon', 'finish');
    const icon = L.icon({
      iconSize: [25, 32],
      iconAnchor: [13, 28],
      iconUrl: `/map/icons/${isStart ? 'start' : 'finish'}.png`,
    });

    startDrawing(
      MAP_SHAPES.MARKER,
      {
        finishOn: 'click',
        markerStyle: {
          draggable: true,
          icon,
          isTakeoff: isStart,
          isLanding: !isStart,
        },
      },
      true
    );

    localStorage.setItem('lastZoomValue', JSON.stringify(window.map.getZoom()));
    localStorage.setItem(
      'lastCoordinatesViewValue',
      JSON.stringify(window.map.getBounds().getCenter())
    );
  };

  const drawThreat = () => {
    const icon = L.icon({
      iconSize: [25, 32],
      iconAnchor: [13, 28],
      iconUrl: `/map/icons/threat.svg`,
    });

    startDrawing(
      MAP_SHAPES.MARKER,
      {
        finishOn: 'click',
        markerStyle: {
          draggable: false,
          isThreat: true,
          icon,
        },
      },
      true
    );
  };

  const drawDangerSector = () => {
    const icon = L.icon({
      iconSize: [25, 32],
      iconAnchor: [13, 28],
      iconUrl: `/map/icons/threat.svg`,
    });

    startDrawing(
      MAP_SHAPES.MARKER,
      {
        finishOn: 'click',
        markerStyle: {
          draggable: false,
          isDangerSector: true,
          icon,
        },
      },
      true
    );
  };

  const drawTargetPoint = () => {
    const originPosition = store.getters['map/nextIndex'];
    const icon = getIconWithText(
      originPosition,
      `/map/icons/orange-marker.svg`,
      'is-target-point-marker'
    );
    startDrawing(
      MAP_SHAPES.MARKER,
      {
        finishOn: 'click',
        markerStyle: {
          draggable: false,
          isTargetPoint: true,
          originPosition,
          icon,
        },
      },
      true
    );
  };
  const getCalculations = (withUserSettings = false) => {
    store
      .dispatch('getCalculations', withUserSettings)
      .then(() => console.debug('Calculations successfully fetched.'))
      .catch((error) => console.error('Error during the fetching calculations: ', error));
  };

  const showCurrentGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        localStorage.setItem('currentGeoLocation', JSON.stringify([latitude, longitude]));

        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        if (window.currentGeoLocationMarker) {
          window.currentGeoLocationMarker.remove();
        }
        const maker = L.marker([latitude, longitude], {
          icon: L.icon({
            iconSize: [25, 32],
            iconAnchor: [13, 28],
            iconUrl: `/map/icons/home.png`,
          }),
        });
        maker.addTo(window.map).bindPopup(
          L.popup({
            offset: [0, -25],
          }).setContent('I am here')
        );
        window.currentGeoLocationMarker = maker;

        window.map.panTo({ lat: latitude, lng: longitude });
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const customPointEditingName = computed(() => store.getters['map/getNameOfEditingCustomPoint']);
  const onCustomPointEditSuccess = (event) =>
    event && store.commit('map/editCustomPointSuccess', [event.lon, event.lat]);
  const onCustomPointEditCancel = () => store.commit('map/toggleModalEditCustomPoint', null);
  const isCustomPointVisible = computed(
    () => store.getters['map/isCustomPointCoordinatesEditVisible']
  );

  const hasFlightPath = computed(() => store.getters['map/cpp_geoJson'].length);
  useSelectedPolygonTabBinder(store);
  const { onClearSelectionViewBar } = useSelectedPolygonDraw(store);

  const toggleMap3DMode = () => {
    store.commit('toggleMap3DMode')
  }

  return {
    isDrawing,
    drawPolygon,
    drawPoint,
    drawThreat,
    drawDangerSector,
    drawLine,
    drawStartFinish,
    getCalculations,
    drawPolygonByLines,
    showCurrentGeoLocation,
    drawCustomPointByCoords,
    setVisibleCoordsModal,
    onClearSelectionViewBar,
    isCoordsModalVisible,
    isTakeOffEditCoodrsModalVisible,
    isLandingEditCoodrsModalVisible,
    setTakeOffVisibleCoordsModal,
    setLandingVisibleCoordsModal,
    onEditTakeOffCoords,
    onEditLandingCoords,
    customPointEditingName,
    onCustomPointEditSuccess,
    onCustomPointEditCancel,
    isCustomPointVisible,
    hasFlightPath,
    clearPrevMapEvents,
    drawTargetPoint,
    toggleMap3DMode
  };
}
