import { getUbConnection } from '@/services/connect';
import axios from 'axios';
import { processCPPDataWithMultipolygonSetting } from '@/utils/mapUtils';
import { SAVE_ORIGIN_FOOTPRINT_BY_DEFAULT } from '@/config/map-config';
import moment from 'moment';
import { getCenterOfPolygon } from '../utils/mapUtils';

const baseURL = process.env.VUE_APP_UB_HOST;
const cppApiURL = process.env.VUE_APP_CPP_API;
const weatherApiURL = process.env.VUE_APP_WEATHER_API;

const isDev = process.env.VUE_APP_API_TO_USE === 'v2'; //process.env.NODE_ENV !== 'production';

const plain_data_table_name = isDev ? 'fp_plain_data' : 'geo_plain_data';
const plains_profile_table_name = isDev ? 'fp_plain_profile' : 'dict_technical_support';
const cameras_profile_table_name = isDev ? 'fp_camera_profile' : 'geo_camera';
const mission_profiles_table_name = 'fp_mission';
const correlation_table_name = 'fp_plane_profile_corr';
const license_table_name = 'fp_license';
const enum_repo_name = 'ubm_enum';

const api = axios.create({
  baseURL,
});

const weatherApi = axios.create({
  baseURL: weatherApiURL,
});
/***
 *
 * @type Service {{
 * @type Service.getMissionIfExist(*=): Promise<undefined|*>,
 * @type Service.getHeights(*, *=): Promise<[]|*>,
 * @type Service.deleteMissions(*=): Promise<*>,
 * @type Service.getCalculations(*=): Promise<undefined|*>,
 * @type Service.getMissions({sortBy?: *, filterByName?: *}): Promise<*>,
 * @type Service.getWeather(): Promise<*>,
 * @type Service.getCppData(*=, *=): Promise<unknown>,
 * @type Service.saveData(*=): Promise<*>,
 * @type Service.setCppFile(*=, *=): Promise<*>,
 * @type Service.connect(): Promise<void>,
 * @type Service.getHeightConstant(*): Promise<number|number|string|SVGAnimatedNumber>}}
 */

const service = {
  async connect() {
    //ToDO: Remove that's only for localDebug!
    await getUbConnection({ login: 'admin', password: isDev ? 'admin' : 'admin' });
  },
  async getAccGeoJson(externalID) {
    const { conn: connection } = window;
    if(!connection || !externalID) return null
    return connection
      .Repository('fp_plain_data_acc')
      .attrs('geoJson')
      .where('externalID', '=', externalID)
      .selectScalar();
  },
  async getMissionIfExist(ID) {
    const { conn: connection } = window;
    if (!connection || !Number(ID)) return;
    return connection
      .Repository(plain_data_table_name)
      .attrs('*')
      .where('ID', '=', Number(ID))
      .selectSingle();
  },
  async getCalculations(missionType, formattedGeoJSON) {
    const { conn: connection } = window;
    if (!connection || !formattedGeoJSON) return;

    formattedGeoJSON.roiParams = formattedGeoJSON.roiParams.map((item) => {
      const { lat, lng } = getCenterOfPolygon(item.geometry);
      return {
        ...item,
        centroid: { type: 'Point', coordinates: [Number(lng.toFixed(6)), Number(lat.toFixed(6))] },
      };
    });

    if (window.messageMarker) {
      window.messageMarker.removeFrom(window.map);
    }

    const session = await connection.authorize();
    return new Promise((resolve, reject) => {
      const ws = new WebSocket(cppApiURL);
      let closeHandled = false;
      /** @type Object.<string, number|boolean> Stage progresses: number = n% or false = pending or true = complete */
      const stageProgresses = {};
      ws.onopen = () => {
        ws.send(JSON.stringify({ type: 'Auth', payload: session.signature() }));
        ws.send(JSON.stringify({ type: missionType, payload: formattedGeoJSON }));
        ws.onmessage = (e) => {
          const messageData = JSON.parse(e.data);
          switch (messageData.type) {
            case 'Progress':
              Object.assign(stageProgresses, messageData.payload);
              console.log('Progress: ', stageProgresses);
              break;
            case 'Result':
              closeHandled = true;
              resolve({ data: messageData.payload });
              break;
            case 'FatalError':
              closeHandled = true;
              reject(messageData.payload);
              break;
            default:
              closeHandled = true;
              reject(`Unknown message type "${messageData.type}" from server`);
              ws.close();
          }
        };
      };
      ws.onclose = (code, reason) => {
        if (!closeHandled) {
          reject(`Connection closed enexpectedly: (${code}) ${reason}`);
        }
      };
    });
  },
  async getCppData(id, cpp_fileData) {
    const { conn: connection } = window;
    const fileOptions = JSON.parse(cpp_fileData);
    const FileURL = await connection.getDocumentURL({
      entity: plain_data_table_name,
      attribute: 'cpp_geoJson',
      fileName: 'cpp_geoJson.json',
      origName: 'cpp_geoJson.json',
      id: Number(id),
      ...fileOptions,
    });
    return api
      .get(FileURL)
      .then(({ data }) => data)
      .catch((e) => console.error('Error during the loading calculation info.', e));
  },
  async saveData(data) {
    const { ID } = data;
    const reqBody = Object.assign({}, data);

    const { conn: connection } = window;
    const geoJSONParsed = JSON.parse(data.geoJson);
    const geoJSON = Array.isArray(geoJSONParsed) ? geoJSONParsed[0] : geoJSONParsed;

    Object.assign(reqBody, { name: reqBody.name || 'additionalServiceData' });

    if (reqBody.ConstantAltitude) {
      Object.assign({ ConstantAltitude: !!reqBody.ConstantAltitude ? 'YES' : 'NO' });
    }
    if (!!geoJSON) {
      const formattedJSON = JSON.stringify({
        ...geoJSON,
        features: geoJSON.features.filter(
          (e) => !((e?.geometry?.type === 'Point' && !e?.properties?.isTargetPoint) && !e?.properties?.isExcluded)
        ),
      });
      Object.assign(reqBody, { geoJson: formattedJSON });
    }

    const cpp_data =
      typeof data.cpp_geoJson === 'string' ? JSON.parse(data.cpp_geoJson) : data.cpp_geoJson;

    if (cpp_data && Array.isArray(cpp_data) && cpp_data.length !== 0) {
      if (data.ID) {
        const processedCppData = processCPPDataWithMultipolygonSetting(
          cpp_data,
          !!SAVE_ORIGIN_FOOTPRINT_BY_DEFAULT
        );

        const file = await service.setCppFile(data.ID, processedCppData);
        Object.assign(reqBody, { cpp_geoJson: file });
      } else {
        Object.assign(reqBody, { cpp_geoJson: null });
      }
    }
    if (!reqBody.ID) delete reqBody['ID'];

    return !!data.ID
      ? connection.updateAsObject({
          entity: plain_data_table_name,
          fieldList: ['*'],
          execParams: { ...reqBody },
        })
      : connection
          .insertAsObject({ entity: plain_data_table_name, fieldList: ['*'], execParams: reqBody })
          .then(
            (response) =>
              new Promise((res, rej) => {
                if (!cpp_data || !Array.isArray(cpp_data) || cpp_data.length === 0) res(response);

                const processedCppData = processCPPDataWithMultipolygonSetting(
                  cpp_data,
                  !!SAVE_ORIGIN_FOOTPRINT_BY_DEFAULT
                );

                service
                  .setCppFile(response.ID, processedCppData)
                  .then((file) =>
                    connection.updateAsObject({
                      entity: plain_data_table_name,
                      fieldList: ['ID', 'cpp_geoJson', 'mi_modifyDate'],
                      execParams: {
                        ID: response.ID,
                        cpp_geoJson: file,
                        mi_modifyDate: response.mi_modifyDate,
                      },
                    })
                  )
                  .then(res);
              })
          );
  },
  async getHeightConstant(coords) {
    const { conn: connection } = window;
    if (!connection) return null;

    const { data } = await connection.post(
      `rest/${plain_data_table_name}/getHeightByCoords`,
      JSON.stringify({
        coords,
      }),
      { responseType: 'json' }
    );

    return data[0]?.elevation;
  },
  async getHeights(coords, step = 500) {
    const { conn: connection } = window;
    if (!connection) return [];

    const { data } = await connection.post(
      `rest/${plain_data_table_name}/getHeightsByCoordsArray`,
      JSON.stringify({ coords, step }),
      {
        responseType: 'json',
      }
    );

    return data;
  },
  async setCppFile(missionId, cppData) {
    const { conn: connection } = window;
    const binGeoJson = btoa(JSON.stringify(cppData));

    return await connection.setDocument(binGeoJson, {
      entity: plain_data_table_name,
      attribute: 'cpp_geoJson',
      id: missionId,
      fileName: 'cpp_geoJson.json',
      origName: 'cpp_geoJson.json',
      encoding: 'base64',
    });
  },

  async getMissions({ sortBy, filterByName = null, searchCondition, paginationOptions }) {
    const { conn: connection } = window;
    let missionsList = connection.Repository(plain_data_table_name).attrs([
      'ID',
      'name',
      'isFavorite',
      'mi_modifyDate',
      'mi_createDate',
    ]);
    if (filterByName) {
      missionsList = missionsList.where('name', 'like', `%${filterByName}%`);
    }
    if ('isFavorite' in searchCondition) {
      missionsList = missionsList.where('isFavorite', '=', searchCondition.isFavorite ? 1 : 0);
    }
    if (paginationOptions) {
      missionsList = missionsList
        .start(paginationOptions.currentPage)
        .limit(paginationOptions.limit);
    }
    if (sortBy && Object.keys(sortBy).length) {
      Object.keys(sortBy).forEach((key) => {
        missionsList = missionsList.orderBy(key, sortBy[key]);
      });
    } else {
      missionsList = missionsList.orderBy('mi_modifyDate', 'desc');
    }

    missionsList.withTotal();
    missionsList = missionsList.limit(50);
    return missionsList.selectAsObject().then((data) => {
      const totalCounts = missionsList.rawResult.total;
      const dataItems = data;
      return {
        totalCounts,
        dataItems,
      };
    });
  },

  async deleteMissions(missionId) {
    const { conn: connection } = window;

    const missionExists = await connection
      .Repository(plain_data_table_name)
      .attrs('ID')
      .where('ID', '=', Number(missionId))
      .selectSingle();
    if (!missionExists) throw new Error('Mission with ID: ' + missionId + ' is not found!');

    return connection.run({
      method: 'delete',
      entity: plain_data_table_name,
      execParams: {
        ID: missionId,
      },
    });
  },
  async getCorrelationByPlainProfileID(ID) {
    const { conn: connection } = window;
    return connection
      .Repository(correlation_table_name)
      .attrs(['windSpeed', 'airSpeed', 'consumption'])
      .where('profileID', '=', Number(ID))
      .selectAsObject();
  },
  async getPlains() {
    const { conn: connection } = window;
    const plains = await (
      await connection
        .Repository('fp_license_uav')
        .where('licenseID.status', '=', 'ACTIVATED')
        .attrs([
          'uavID.ID',
          'uavID.name',
          'alias',
          'uavID.type',
          'uavID.maxPowerAvailable',
          'uavID.batteryNominalVoltage',
          'uavID.maxPowerAvailableEditable',
          'uavID.maxAscGradient',
          'uavID.maxDscGradient',
          'uavID.loiterRadius',
          'uavID.cruisingAirSpeed',
          'uavID.cruisingPowerConsumption',

          'uavID.takeoffPitch',
          'uavID.takeoffAltMin',
          'uavID.helixRadius',
          'uavID.bellyLandInitAlt',

          // Parachute
          'uavID.parachute',
          'uavID.parachuteDepAltDef',
          'uavID.parachuteDepAltDefEditable',
          'uavID.openDelay',
          'uavID.parachuteDepAltMin',
          'uavID.parachuteDepAltMax',
          'uavID.parachuteVertSpeed',
          'uavID.bellyLandingGradient',
          'uavID.flareLine',
          'uavID.approachGradient',
          'uavID.afterParachuteGradient',
          'uavID.parachuteChannel',
          'uavID.openPwm',
          //'takeoffAltMax'
        ])
        .selectAsObject({
          'uavID.ID': 'ID',
          'uavID.name': 'label',
          'uavID.type': 'type',
          'uavID.maxPowerAvailable': 'powerCount',
          'uavID.batteryNominalVoltage': 'batteryNominalVoltage',
          'uavID.maxPowerAvailableEditable': 'maxPowerAvailableEditable',
          'uavID.maxDscGradient': 'DescentGradient',
          'uavID.maxAscGradient': 'AscentGradient',
          'uavID.loiterRadius': 'LoiterRadius',
          'uavID.cruisingAirSpeed': 'CruiseAirSpeed',
          'uavID.cruisingPowerConsumption': 'CruisePowerConsumption',
          'uavID.parachuteDepAltDef': 'parachuteDepAltDef',
          'uavID.parachuteDepAltDefEditable': 'parachuteDepAltDefEditable',

          'uavID.takeoffPitch': 'takeoffPitch',
          'uavID.takeoffAltMin': 'takeoffAltMin',
          'uavID.helixRadius': 'helixRadius',
          'uavID.bellyLandInitAlt': 'bellyLandingInitialAltitude',

          'uavID.parachute': 'hasParachute',
          'uavID.openDelay': 'openDelay',
          'uavID.parachuteDepAltMin': 'minDeployAltitude',
          'uavID.parachuteDepAltMax': 'maxDeployAltitude',
          'uavID.parachuteVertSpeed': 'verticalSpeed',
          'uavID.bellyLandingGradient': 'bellyLandingGradient',
          'uavID.approachGradient': 'approachGradient',
          'uavID.flareLine': 'flareLine',
          'uavID.afterParachuteGradient': 'afterParachuteGradient',
          'uavID.parachuteChannel': 'parachuteChannel',
          'uavID.openPwm': 'parachuteOpenPwm',
        })
    ).map(({ alias, label, parachuteDepAltDefEditable, maxPowerAvailableEditable, ..._ }) => ({
      ..._,
      label: alias || label,
      patrolGradient: _.AscentGradient,
      parachuteDepAltDefDisabled: !parachuteDepAltDefEditable,
      powerCountDisabled: !maxPowerAvailableEditable,
    }));
    return await Promise.all(
      plains.map(async (plain) => {
        const OptimalAirSpeeds = await service.getCorrelationByPlainProfileID(plain.ID);
        return {
          ...plain,
          OptimalAirSpeeds,
        };
      })
    );
  },
  async getCamera() {
    const { conn: connection } = window;
    return await connection
      .Repository(cameras_profile_table_name)
      .attrs([
        'ID',
        'name',
        'frameWidth',
        'frameHeight',
        'matrixWidth',
        'matrixHeight',
        'focalLength',
      ])
      .selectAsObject({
        name: 'label',
        frameWidth: 'FrameSizePx_x',
        frameHeight: 'FrameSizePx_y',
        matrixWidth: 'FrameSizeMm_x',
        matrixHeight: 'FrameSizeMm_y',
        focalLength: 'FocalLength',
      });
  },
  async getWeather([latitude, longitude], { dateFrom, dateTo, ...otherConditions }) {
    const { conn: connection } = window;

    const today = moment(new Date()).format('YYYY-MM-DD');
    const queryParams = {
      latitude,
      longitude,
      start_date: dateFrom || dateTo ? dateFrom || dateTo : today,
      end_date: dateTo || dateFrom ? dateTo || dateFrom : today,
      ...otherConditions,
    };

    return connection.get(`rest/${plain_data_table_name}/proxyWeatherRequest`, {
      params: queryParams,
    }); //weatherApi.get('/', { params: queryParams });
  },
  async updateMissionByID(ID, data) {
    const { conn: connection } = window;
    return connection.updateAsObject({
      entity: plain_data_table_name,
      fieldList: ['ID'].concat(Object.keys(data)),
      execParams: { ID, ...data },
    });
  },
  async getMissionProfiles() {
    const { conn: connection } = window;
    return (
      await connection
        .Repository('fp_license_mission')
        .attrs([
          'missionID',
          'missionID.missionType',
          'missionID.name',
          'missionID.type',
          'alias',
          'missionID.missionParams'
        ])
        .where('licenseID.status', '=', 'ACTIVATED')
        .selectAsObject({
          missionID: 'ID',
          'missionID.name': 'label',
          'missionID.missionType': 'missionType',
          'missionID.type': 'type',
          alias: 'alias',
          'missionID.missionParams': 'params',
        }))
        .map(({
          alias,
          label,
          params: {
            minSafeAlt: minSafeAltitudeAGL,
            scanStepSide: scanStepSideways,
            scanStepForw: scanStepForward,
            safetyMargin: safetyMargin,
            stripWidthKoef: stripWidthKoeff,
            landingMode: landingMode,
            flightAlt: H_AGL_Flight,
            overshootRoute: LevelingDistance,
            overshootZone: L_OvershootLine_Zone,
            lineSpacing: LineSpacing,
            beginEndLine: beginEndLength,
            overExtra: overExtra,
            altDef: ConstantAltitude,
            sideOverlapDef,
            forwardOverlapDef,
            gsdDef,
            ...paramsData
          },
          ...missionData
        }) => ({
          minSafeAltitudeAGL,
          scanStepSideways,
          scanStepForward,
          safetyMargin,
          stripWidthKoeff,

          gsdDef,
          gsdMax: gsdDef,

          sideOverlapDef,
          sideOverlapMin: sideOverlapDef,

          forwardOverlapDef,
          forwardOverlapMin: forwardOverlapDef,
          landingMode,
          H_AGL_Flight,
          LevelingDistance,
          L_OvershootLine_Zone,
          LineSpacing,
          beginEndLength,
          overExtra,
          ConstantAltitude: ConstantAltitude === 'YES',
          ...paramsData,
          label: alias || label,
          ...missionData
        }));
  },
  async getLicenseActiveList() {
    const { conn: connection } = window;
    return await connection
      .Repository(license_table_name)
      .attrs('*')
      .where('status', '=', 'ACTIVATED')
      .selectAsObject();
  },
  async getEnumsOptionsByGroupCode(code) {
    const { conn: connection } = window;
    return await connection
        .Repository(enum_repo_name)
        .attrs('ID', 'code', 'name')
        .where('eGroup', '=', code)
        .selectAsObject();
  },
  async getPayloadProfiles() {
    const { conn: connection } = window;
    return (
        await connection
            .Repository('fp_license_payload')
            .attrs([
              'payloadID',
              'payloadID.sensorWideSizeCm',
              'payloadID.sensorNarrowSizeCm',
              'payloadID.sensorWideSizePx',
              'alias',
              'payloadID.name',
              'payloadID.portraitOrientation',
              'payloadID.sensorNarrowSizePx',
              'payloadID.focalLengthDef',
              'payloadID.triggerTimeMin',
              'payloadID.gimbalYawAngleMax',
              'payloadID.pwmChannel',
              'payloadID.pwmTrim',
              'payloadID.pwmAngleRate'
            ])
            .where('licenseID.status', '=', 'ACTIVATED')
            .selectAsObject({
              'payloadID': 'ID',
              'payloadID.sensorWideSizeCm': 'sensorWideSizeCm',
              'payloadID.sensorNarrowSizeCm': 'sensorNarrowSizeCm',
              'payloadID.sensorWideSizePx': 'sensorWideSizePx',
              'alias': 'alias',
              'payloadID.name': 'label',
              'payloadID.portraitOrientation': 'portraitOrientation',
              'payloadID.sensorNarrowSizePx': 'sensorNarrowSizePx',
              'payloadID.focalLengthDef': 'focalLengthDef',
              'payloadID.triggerTimeMin': 'triggerTimeMin',
              'payloadID.gimbalYawAngleMax': 'gimbalYawAngleMax',
              'payloadID.pwmChannel': 'pwmChannel',
              'payloadID.pwmTrim': 'pwmTrim',
              'payloadID.pwmAngleRate': 'pwmAngleRate'
            })
    ).map(({ alias, label, ConstantAltitude, portraitOrientation, ...data }) => ({
      ...data,
      portraitOrientation: portraitOrientation === 'YES',
      label: alias || label
    }));
  },
  async getPointsProfileList() {
      const { conn: connection } = window;
      return (
          await connection
              .Repository('fp_license_points')
              .attrs([
                  'pointID',
                  'pointID.name',
                  'pointID.type',
                  'pointID.pointType',
                  'alias',
                  'pointID.pointParams'
              ])
              .where('licenseID.status', '=', 'ACTIVATED')
              .selectAsObject({
                  pointID: 'ID',
                  'pointID.name': 'label',
                  'pointID.type': 'type',
                  'pointID.pointType': 'pointType',
                  alias: 'alias',
                  'pointID.pointParams': 'pointParams',
              })
      ).map(({ alias, label, ConstantAltitude, ...data }) => ({
          ...data,
          label: alias || label
      }));
  }
};

export default service;
