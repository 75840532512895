<template>
  <div v-loading="isFetching" :class="isFetching ? 'loader-mask' : ''">
    <VHeader />
    <VMenu />
    <div class="content">
      <VRootMap/>
      <VMap3D v-if="isMap3D" :isVisible="isMap3D" :kmz="kmz"/>
      <!-- <VMapToolBar /> -->
      <div id="handler" class="iconRes">
        <img :src="IconRes" alt="resibale" />
      </div>
      <vue-resizable
        class="vResizable"
        :height="385"
        :min-height="385"
        drag-selector=".handler"
        :disable-attributes="['l', 't', 'w']"
      >
        <VMissionTabs />
      </vue-resizable>
      <VParams />
    </div>
    <VModals />
  </div>
</template>

<script>
import { watch, computed, ref } from 'vue';
import { useStore } from 'vuex';
import VHeader from '@/components/layout/VHeader';
import VMenu from '@/components/layout/VMenu';
import VRootMap from '@/components/FlightPlannerMap';
import VMap3D from '@/components/FlightPlannerMap3D';
import VMissionTabs from '@/components/tabs/VMissionTabs';
import VParams from '@/components/params/VParams';
import { IconRes } from '@/assets/svgs';
import VueResizable from 'vue-resizable';
import VModals from '@/components/modals/VModalsGlobal';
import useExport from '@/hooks/useExport'

export default {
  name: 'VLayout',
  components: { VMissionTabs, VMenu, VHeader, VRootMap, VueResizable, VParams, VModals, VMap3D },
  setup() {
    const kmz = ref(null)
    const store = useStore()
    const { exportTo3DMap } = useExport(['rows','list'], true)

    const isMap3D = computed(() => store.state.isMap3D)
    const isFetching = computed(() => store.state.isFetching)

    watch(isMap3D, async () => {
      if(isMap3D.value) {
        kmz.value = await exportTo3DMap()
      } else {
        kmz.value = []
      }
    })

    return {
      isMap3D,
      isFetching,
      kmz
    }
  },
  computed: {
    IconRes() {
      return IconRes;
    },
  },
};
</script>

<style lang="scss" src="@/styles/components/layout/layout.scss" scoped></style>