import Vuex from 'vuex';

import map from './map';
import params from './params';
import heights from './heights';
import debug, { errorLevels } from './debug';
import exports from './exports';
import missions from './missions';
import currentGeolocation from './current-geolocation';
import weather from './weather';
import threat from './threat';
import sections from "./sections";
import targetPoints from './target-points';

import service from '@/services/service';
import { routerMerge } from '@/router/utils';
import {
  convertGeoJsonDataToFlightPlanerRequestType,
  formatResponseFromCpp,
  getFormattedProperties,
  processInputCalculatorGeoJSON,
  weatherToCPPRequestType,
} from '@/utils/mapUtils';
import {
  buildWeatherToSave, createCalculationRequestData, PrefetchValidator
} from '@/utils/serviceHelper'
import isDraftChecker from '@/plugins/vuex/is-draft';

//const debug = process.env.NODE_ENV !== 'production';

const DEFAULT_MISSION_NAME = 'Mission name';

export default new Vuex.Store({
  modules: {
    map,
    params,
    heights,
    debug,
    exports,
    missions,
    currentGeolocation,
    weather,
    threat,
    sections,
    targetPoints
  },
  state: {
    serverVersion: '0.0.1',
    isFetching: false,
    resetOnSave: false,
    isDraft: false,
    isThreatModule: false,
    isMap3D: false
  },
  mutations: {
    setFetching: (state, isFetching) => (state.isFetching = isFetching),
    setServerVersion: (state, version) => (state.serverVersion = version),
    setThreatModule: (state, payload) => (state.isThreatModule = payload),
    setResetOnSave: (state, value) => (state.resetOnSave = value),
    markAsDraft: (state) => (state.isDraft = true),
    resetDraftMark: (state) => (state.isDraft = false),
    toggleMap3DMode: (state) => (state.isMap3D = !state.isMap3D),
  },
  actions: {
    async setMissionResponse({ commit }, response) {
      const accGeoJson = await service.getAccGeoJson(response.externalID)
      const {
        geoJson,
        threats,
        cpp_geoJson: cppFileInfo,
        additionalGeometry,
        importedGeoJsonData,
        ...params
      } = response;

      const cpp_geoJson = cppFileInfo && (await service.getCppData(params.ID, cppFileInfo));

      commit('debug/clearErrorState');
      commit('map/initMapStore', { geoJson, cpp_geoJson, additionalGeometry, importedGeoJsonData, accGeoJson });
      commit('threat/setThreats', threats);
      if (params.waypoints && typeof params.waypoints === 'string') {
        try {
          params.waypoints = JSON.parse(params.waypoints);
        } catch {}
      }
      commit('params/initPramsState', params);
      commit('params/mergePlain', { ...params, ID: params.plain });
    },

    // Update/Create request;
    async save({ commit, dispatch, getters, state }, { missionName = null, previewUrl = null }) {
      commit('setFetching', true);
      try {
        if(state.params.isReadOnly) {
          throw new Error(`Зміна місії неможлива. Завдання № ${state.params.name} по поточній місії виконано`)
        }
        // Important to understood we are looking on ID property adn in depends on existing doing update/create action;
        const data = { ...getters['params/getParamsAll'], ...getters['map/getRawData'] };

        const threats = state.threat.threats
        if(threats) {
          data.threats = JSON.stringify(threats)
        }

        if (missionName) {
          delete data.ID;
          delete data.externalID;
          delete data.isReadOnly;
          delete data.externalUserName;
        }

        console.log(data)
        Object.assign(data, {
          name: missionName || data.name || DEFAULT_MISSION_NAME,
          previewUrl: previewUrl || data.previewUrl,
        });

        const response = await service.saveData(data);

        await dispatch('setMissionResponse', response)

        if (state.resetOnSave) {
          if (typeof window.customEventAfterSave === 'function') {
            customEventAfterSave();
          } else {
            dispatch('resetAll');
            routerMerge({ query: { mission: 'new' } });
          }
        } else {
          routerMerge({ query: { mission: response.ID } });
        }
        commit('resetDraftMark');
      } catch (e) {
        commit('debug/addLog', {
          message: `Error: during the saving mission ${missionName || ''}`,
          details: e,
          level: errorLevels.error,
        });
      } finally {
        commit('setFetching', false);
        commit('setResetOnSave', false);
      }
    },
    async getCalculations({ commit, dispatch, getters, state }, withUserSettings = false) {
      commit('setFetching', true);
      commit('debug/clearErrorState');

      try {
        if(state.params.isReadOnly) {
          throw new Error(`Зміна місії неможлива. Завдання № ${state.params.name} по поточній місії виконано`)
        }
        const allParams = getters['params/getParamsAll'];
        const currentPlain = getters['params/getCurrentPlainData'];
        const powerCount = allParams.powerCount * (currentPlain?.batteryNominalVoltage || 0);
        const data = { ...allParams, powerCount, ...getters['map/getRawData']};
        const targetPoints = getters['map/missionFeatures'].filter(e => e.properties?.isTargetPoint);
        const forecastData = buildWeatherToSave(state);
        const minPlainSpeed = Math.min(
            ...(state.params.OptimalAirSpeeds || []).map(({ AirSpeed, airSpeed }) => AirSpeed || airSpeed)
        );
        const maxForecastWindSpeed = Math.max(...forecastData.map(({ wind_speed }) => wind_speed));

        const prefetchValidator = new PrefetchValidator(
            (errorText) => commit('debug/addExtraError', errorText),
            state
        );

        prefetchValidator.addRule(({ parachuteDepAltDef, maxDeployAltitude }) => !(parachuteDepAltDef <= maxDeployAltitude),
            `Parachute Deployment Alt MAX (m) is ${state.params.maxDeployAltitude}, Parachute dep alt (m) is ${state.params.parachuteDepAltDef}`)

        prefetchValidator.addRule(({ longitude_start, latitude_start }) =>  !(Number(longitude_start) && Number(latitude_start)),
            'Selection of the takeoff point is required!');

        prefetchValidator.addRule(({ longitude_end, latitude_end }) => !(Number(longitude_end) && Number(latitude_end))
            ,'Selection of the landing point is required!');

        prefetchValidator.addRule(({ plain }) => !plain, 'Select the profiles in the blocks: "Flight Parameters"')

        prefetchValidator.addRule(({ missionProfile }) => !missionProfile, 'Select the profiles in the blocks: "Mission parameters"');

        prefetchValidator.addRule(({ payloadProfile }) => !!getters['params/isPhotogrammetry'] && !payloadProfile, 'Select the profiles in the blocks: "Photogrammetry parameters"');

        prefetchValidator.addRule(() => minPlainSpeed <= maxForecastWindSpeed,
            `Mission Impossible!, with presented values: "Plane speed": ${minPlainSpeed || 0}, "Wind speed": ${maxForecastWindSpeed || 0}`)

        targetPoints.forEach(targetPoint => {
          prefetchValidator.addRule(({ pointsProfiles, missionProfiles, missionProfile: currentProfileID }) => {
            const pointProfile = pointsProfiles.find(({ ID }) => Number(targetPoint?.properties?.pointType) === ID);
            const missionProfile = missionProfiles.find(({ ID }) => ID === currentProfileID);
            return missionProfile?.type !== pointProfile?.type;
          },  `Mission Impossible!, type of "#${targetPoint?.properties?.originalPosition} Point", is miss matching with mission type`)
          })

        if (!prefetchValidator.isValid) {
          return commit('setFetching', false);
        }

        const cppRequestData = createCalculationRequestData(data, state, forecastData, withUserSettings, getters['params/getTargetPointData']);
        const { data: geoJsonFromResponse } = await service.getCalculations(state.params.missionType, cppRequestData);

        dispatch('map/setAdditionalGeometryFromBack', {
          geoJsonFromResponse:
            geoJsonFromResponse?.features?.filter?.((item) => item.id === 'ScanDirection') ?? [],
          properties: getFormattedProperties(state.params, getters['params/getStartPointCoordinates']),
          withUserSettings: true,
        });

        let { cpp, fpCount, routeLength, powerUsed, waypoints } = await formatResponseFromCpp(
          geoJsonFromResponse,
          { ...state.params, batteryNominalVoltage: currentPlain.batteryNominalVoltage },
          cppRequestData
        );

        commit('map/setCppGeoData', cpp);
        commit('params/setCalculationResults', { fpCount, waypoints, routeLength, powerUsed });

        if (powerUsed > state.params.powerCount)
          commit('debug/addLog', {
            message: `Error! Insufficient power capacity (${powerUsed} > ${state.params.powerCount})`,
            level: errorLevels.error,
          });
        commit('setFetching', false);
        commit('markAsDraft');
      } catch (e) {
        commit('debug/addLog', {
          message: 'Mission processing error!',
          details: e,
          level: errorLevels.error,
        });
        commit('debug/addExtraError', 'Calculation have not been done!');
        commit('debug/addExtraError', `Calculation failed with error: ${e}`);
        commit('setFetching', false);
      }
    },
    async initStore({ commit, dispatch }, missionId) {
      await dispatch('resetAll', missionId);
      const response = await service.getMissionIfExist(missionId);
      if (!response) return;

      await dispatch('setMissionResponse', response)
    },

    async resetAll({ commit, dispatch }, missionId) {
      !missionId && routerMerge({ query: { mission: 'new' } });
      commit('setResetOnSave', false);
      commit('map/reset');
      commit('params/reset');
      commit('heights/reset');
      commit('exports/reset');
      commit('missions/reset');
      commit('weather/reset');
      commit('debug/reset');
      commit('threat/reset');
      commit('resetDraftMark');
      //! doesn't reset them in 'params/reset'
      await dispatch('params/fetchProfiles');
      await dispatch('threat/getThreats');
    },
  },
  plugins: [isDraftChecker]
});
