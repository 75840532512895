<script setup>
import { computed, onMounted, ref } from 'vue';
import VHeaderMenuItem from '@/components/layout/components/VHeaderMenuItem.vue';
import useMapEvents from '@/hooks/useMapEvents';
import useGlobalEvents from '@/hooks/useGlobalEvents';
import { useStore } from 'vuex';
import { LogoHeader } from '@/assets/svgs';

const { VUE_APP_CURRENT_VERSION: frontVersion = '0.0.1' } = process.env;

const serverVersion = computed(() => store.state.serverVersion);

const store = useStore();
const title = computed(() => store.state.params.name || 'Untitled');
const isReadOnly = computed(() => store.state.params.isReadOnly);

onMounted(() => {
  const {
    VUE_APP_PRODUCT_NAME: name,
    VUE_APP_CURRENT_VERSION: version,
    VUE_APP_YEAR_OF_CURRENT_VERSION: year,
  } = process.env;

  // title.value = `${name || 'Flight creator'} ${year || '2024'} v. ${version || '0.0.1'}`;
});
const {
  getCalculations,
  showCurrentGeoLocation,
  setVisibleCoordsModal,
  drawStartFinish,
  drawPolygonByLines,
  drawLine,
  drawPolygon,
  drawPoint,
  drawCustomPointByCoords,
} = useMapEvents();

const {
  onSaveAsClick,
  onLogoutClick,
  onCreateNewClick,
  onOpenExistingClick,
  onMissionImportClick,
  onExportClick,
  onImportClick,
  onSaveClick,
  onOpenFileManagerClick,
  onSetTakeOffByCurrentGeoLocation,
  onSetLandingByCurrentGeoLocation,
  onSaveAsMangerClick,
  onSaveManagerClick,
  isThreatModule,
} = useGlobalEvents();

const menuHierarchy = [
  {
    title,
    isReadOnly,
    header: true,
    children: [
      {
        title: 'File',
        children: [
          {
            title: 'Create new mission',
            click: onCreateNewClick,
          },
          // {
          //   title: 'Open existing mission',
          //   click: onOpenExistingClick,
          // },
          {
            title: 'Import mission components',
            click: onOpenExistingClick,
          },
          {
            title: 'Save current mission',
            click: onSaveManagerClick,
          },
          {
            title: 'Save mission as...',
            click: onSaveAsMangerClick,
          },
          {
            title: 'Import from KML/KMZ',
            children: [
              {
                title: 'Import to KML',
                click: onImportClick,
              },
              ...(isThreatModule.value
                ? [
                    {
                      title: 'Import to Threat',
                      click: onImportThreatsClick,
                    },
                  ]
                : []),
            ],
          },
          {
            title: 'Export to KMZ',
            click: onExportClick,
          },
        ],
      },
      {
        title: 'Tools',
        children: [
          {
            title: 'Canvas tools',
            children: [
              {
                title: 'Create new mission',
                click: onCreateNewClick,
              },
              {
                title: 'Open existing mission',
                click: onOpenExistingClick,
              },
              {
                title: 'Import mission components',
                click: onOpenExistingClick,
              },
              {
                title: 'Save current mission',
                click: onSaveManagerClick,
              },
              {
                title: 'Save mission as...',
                isItemBreak: true,
                click: onSaveAsMangerClick,
              },
              {
                title: 'Import from KML/KMZ',
                click: onImportClick,
              },
              {
                title: 'Export to KMZ',
                click: onExportClick,
              },
            ],
          },
          {
            title: 'Danger Zone',
          },
          {
            title: 'Set location',
            click: () => drawPoint(false),
          },
          {
            title: 'Set takeoff',
            click: () => drawStartFinish(true),
          },
          {
            title: 'Move takeoff to current location',
            click: onSetTakeOffByCurrentGeoLocation,
          },
          {
            title: 'Set landing',
            click: () => drawStartFinish(false),
          },
          {
            title: 'Move landing to current location',
            click: onSetLandingByCurrentGeoLocation,
          },
          {
            title: 'Set mission area',
            click: () => drawPolygonByLines(),
          },
          {
            title: 'Set mission path',
            click: () => drawLine(),
          },
          {
            title: 'Build optimised flight path',
            click: getCalculations,
          },
        ],
      },
      {
        title: 'Settings',
      },
      {
        title: 'Help',
      },
      {
        title: 'About',
      },
    ],
  },
];
</script>

<template>
  <div class="header">
    <img :src="LogoHeader" class="logoHeader" alt="logo" />
    <div class="menu">
      <el-menu
        menu-trigger="hover"
        class="elMenuHeader"
        mode="horizontal"
        popper-offset="17"
        :ellipsis="false"
      >
        <VHeaderMenuItem
          v-for="item in menuHierarchy"
          :key="item?.title"
          :data="item"
          :title="title"
        />
      </el-menu>
    </div>
    <div class="version">{{ `ver. ${frontVersion} / ${serverVersion}` }}</div>
  </div>
</template>

<style lang="scss" src="@/styles/components/layout/header.scss" scoped></style>