//const JSZip = require('jszip/dist/jszip')
//const { Strategy, Config } = require('@iqusion/layer-converter/dist/layer-converter.min.js')
import { SAVE_ORIGIN_FOOTPRINT_BY_DEFAULT } from '@/config/map-config';
import saveAs from 'save-as/src/lib';
import moment from 'moment';
import downScaler from '@/utils/downscaler';
const { buildWaypoints } = require('./json2waypoints');
const { GEO_JSON_IDS: IDS_CONST, FOOTPRINT_STYLES, PATH_STYLES } = require('../config/map-config');
const { DATASETS_OPTIONS } = require('../config/chartConfig');

const { default: polygonsUnion } = require('@turf/union');
const { polygon: toTuftPolygon } = require('@turf/helpers');
const { default: getCenter } = require('@turf/center');
const { default: bearing } = require('@turf/bearing');
const { default: destination } = require('@turf/destination');
const { get } = require('lodash');

const turfLength = require('@turf/length').default;
const turfArea = require('@turf/area').default;
const { point } = require('@turf/helpers');

const coordinatesPostGisStrToLatLang = (coords_str) => {
  const [lat, lng] = coords_str.split('POINT(')[1].replace(')', '').split(' ');
  return [lat, lng];
};
const convertHeightsRespToChartData = (data, maxHeightFromLeaflet) => {
  const maxValueOfHeightFromResp = Math.max(
    ...data.map(({ length_from_start }) => length_from_start)
  );
  const coefficient = maxHeightFromLeaflet / maxValueOfHeightFromResp;
  //SRID=4326;POINT(29.927461303318292 50.20260284703437)

  return data
    .map(({ length_from_start, elevation, coordinates }) => ({
      height: elevation,
      length: Math.round(coefficient * length_from_start),
      coordinates: coordinatesPostGisStrToLatLang(coordinates),
    }))
    .reduce(
      (acc, currentEl) => ({
        ...acc,
        data: [
          ...acc.data,
          {
            x: currentEl.length,
            y: currentEl.height,
            coordinates: currentEl.coordinates,
          },
        ],
      }),
      {
        ...DATASETS_OPTIONS.HEIGHTS_OPTIONS,
        data: [],
      }
    );
};
function convertPathToChartData(path, heightConst = 0, height = []) {
  const calculateHeights = (
    (constValue) => (heightTarget) =>
      constValue + heightTarget
  )(heightConst);

  if (!path) return;
  const feature = path?.features && path?.features[0];
  const {
    label,
    data,
    pointInfo: pointInfoByY,
  } = feature?.geometry?.coordinates?.reduce(
    ({ label, data, pointInfo }, currentEl, index, arr) => {
      if (index) {
        const { length: cppRouteLength } = getGeoJsonMeasurements({
          ...path,
          features: [
            {
              ...feature,
              geometry: {
                ...feature?.geometry,
                coordinates: [...feature?.geometry?.coordinates]?.splice(0, index + 1),
              },
            },
          ],
        });
        return {
          label: [...label, cppRouteLength],
          data: [...data, currentEl[2]],
          pointInfo: [...pointInfo, { id: cppRouteLength, point: currentEl }],
        };
      } else
        return {
          label: ['Початок'],
          data: [currentEl[2] || 0],
          pointInfo: [{ id: currentEl[2] || 0, point: currentEl }],
        };
    },
    { label: [], data: [], pointInfoByY: [] }
  );

  return {
    //pointInfoByY,

    datasets: [height].concat([
      {
        ...DATASETS_OPTIONS.PATHS_OPTIONS,
        data: data.map((y, index) => ({
          y: calculateHeights(Number(y)).toString(),
          x: ((Number(label[index]) || 0) * 1000).toString(),
        })),
      },
    ]),
  };
}
/* ToDO: make sanse to think about implementation own lib for building KML regarding to problems with current lib
async function buildKMLForGoogleEarh(type, fileName, geoJSONs = []) {
  const buildBody = geoJSONs.map(({ geoJSON, properties })=> geoJSON.features.map(e => ({...e, properties}))[0])
    .map(({properties = { width: 3, fill: 0, color: '#409EFF' }, ...e}) => {
      console.log(e)
      let featureKMLString = `<${e?.geometry?.type}>`
      if(properties) {
        featureKMLString += `<Style>
        ${'<LineStyle>'}
          ${Object.keys(properties).map(key => `<${key}>${properties[key]}</${key}>`).join('')}
          ${'</LineStyle>'}
           ${'<PolyStyle>'}	${Object.keys(properties).map(key => `<${key}>${properties[key]}</${key}>`).join('')}
          ${'</PolyStyle>'}
           </Style>`
      }
      // featureKMLString+= "<Style><LineStyle><color>FF008000</color><width>3</width> </LineStyle>  <PolyStyle><fill>0</fill></PolyStyle></Style>"
      featureKMLString += `<altitudeMode>absolute</altitudeMode><coordinates>${e?.geometry?.coordinates?.map(([x, y, z]) => `${x},${y},${Number(z) * 6}`).join('\r\n')} </coordinates>`
      featureKMLString += `</${e?.geometry?.type}>`;
      return featureKMLString.trim();
    }).join('')

  return `<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://earth.google.com/kml/2.0"><Document> <Style id="s_ylw-pushpin"> <IconStyle>\t<scale>0.5</scale> <Icon> <href>http://maps.google.com/mapfiles/kml/pal4/icon25.png</href> </Icon> </IconStyle> <ListStyle>\t</ListStyle> </Style><StyleMap id="m_ylw-pushpin_copy0"> <Pair> <key>normal</key> <styleUrl>#s_ylw-pushpin</styleUrl> </Pair><Pair> <key>highlight</key> <styleUrl>#s_ylw-pushpin_hl</styleUrl> </Pair> </StyleMap>\t<Style id="s_ylw-pushpin_hl">\t<IconStyle>\t<scale>0.5</scale> <Icon> <href>http://maps.google.com/mapfiles/kml/pal4/icon17.png</href> </Icon> </IconStyle> <ListStyle> </ListStyle>\t</Style><Folder><name>Trajectory</name><Placemark><description></description>${buildBody}</Placemark>
</Folder>
</Document>
</kml>`
}
*/
/**** Such implementation that`s max what you can do with current tools */
async function formatKMLForGoogleEarth({ type, fileName, geoJSONWithProperties }) {
  const [, currentStrategy] =
    Object.entries(Strategy).find(([, value]) => value.type === 'kml') || [];
  if (!currentStrategy) throw new Error(`Unsupported type "${type}"`);

  const converter = new Config(
    new currentStrategy({ simplestyle: true, documentName: fileName, name: fileName })
  );

  (geoJSONWithProperties || [])
    .filter(({ geoJSON }) => geoJSON.id === IDS_CONST.path)
    .concat(geoJSONWithProperties.filter(({ geoJSON }) => geoJSON.id !== IDS_CONST.path))
    .forEach(({ geoJSON, properties: { color, ...additionalStyles } }) => {
      const { features: fData, ...geoJsonData } = geoJSON;
      const features = fData?.map((feature) => ({
        ...feature,
        properties: { ...additionalStyles, stroke: color, color },
        altitudeMode: 'absolute',
        geometry: {
          ...feature.geometry,
          altitudeMode: 'absolute',
        },
      }));
      converter.setGeoJSON(
        { ...geoJsonData, features },
        { ...additionalStyles, stroke: color, color }
      );
    });

  const data = await converter.convert({ name: fileName });
  const text = await new window.Blob([data]).text();

  const dataToSave = text.replace(
    /<coordinates>/,
    '<altitudeMode>absolute</altitudeMode><coordinates>'
  );

  if (type === 'kmz') {
    const zip = new JSZip();
    zip.file('geometry.kml', dataToSave);
    const data = await zip.generate({ type: 'base64' });
    const unitArray = await Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
    window.saveAs(new window.Blob([unitArray]), `${fileName}.kmz`);
  } else window.saveAs(new window.Blob([dataToSave]), `${fileName}.${converter.extension}`);
}

function processCPPDataWithMultipolygonSetting(cpp, withOriginFootprint = false) {
  if (!(cpp || []).map((e) => e.id).includes(IDS_CONST.footprint)) return cpp;

  const footPrintCollection = cpp.find((e) => e.id === IDS_CONST.footprint);

  const features = (footPrintCollection.features || [])
    .map((footPrint) => {
      if (!footPrint) return;

      const coordinates = footPrint?.geometry?.coordinates?.flat(1);

      const union = coordinates.reduce((acc, current) => {
        if (!acc) return toTuftPolygon([current], {});
        return polygonsUnion(acc, toTuftPolygon([current], {}), {});
      }, null);

      const coordinatesToSave = union?.geometry?.coordinates
        ?.flat(2)
        ?.every((e) => Array.isArray(e))
        ? union?.geometry?.coordinates?.flat(2)
        : union?.geometry?.coordinates?.flat(1);
      return {
        id: IDS_CONST.multi_footprint,
        geometry: {
          coordinates: coordinatesToSave,
          type: 'LineString',
        },
        type: 'Feature',
      };
    })
    .filter((e) => e);

  const formattedFootprint = {
    ...footPrintCollection,
    id: IDS_CONST.multi_footprint,
    features,
  };

  return [
    ...cpp.filter((e) => (!withOriginFootprint ? e.id !== IDS_CONST.footprint : e)),
    formattedFootprint,
  ];
}

const processInputCalculatorGeoJSON = (geoJsonCollection, withUserSettings = false) => {
  let features = (geoJsonCollection?.features || []).filter(
    ({ properties }) => !properties?.isExcluded && !properties.isHidden
  );
  if (!withUserSettings) {
    features = features.map((item) => {
      const { scanMode, ...rest } = item.properties;
      item.properties = rest;
      return item;
    });
  }
  return {
    ...geoJsonCollection,
    features,
  };
};
const convertGeoJsonDataToFlightPlanerRequestType = ({
  startPoint,
  endPoint,
  geoJSON,
  properties,
  forecast,
  withUserSettings,
  tagetPointData,
}) => {
  const defaultPolygon = geoJSON.find((e) => e.id === IDS_CONST.default || !e.id);
  const vectorCollection = geoJSON.find((e) => e.id === IDS_CONST.vector);

  const weatherInLineString = {
    type: 'MultiLineString',
    coordinates: forecast,
  };
  const existingRois = defaultPolygon?.features?.filter((e) =>
    ['Polygon', 'LineString', 'Point'].includes(e?.geometry?.type)
  );
  //const getRoiProsProxy = (feature, )
  //debugger
  const roisArr = existingRois
    ?.map((feature) => ({
      geometry: feature.geometry,
      ...properties.zoneParamsDef,
      ...feature.properties,
      ...(feature.properties.isTargetPoint ? { pointData: tagetPointData[feature.properties.pointType]} : {})
    }))
    .map((x) => (x.scanMode && (x.scanMode = x.scanMode.replace('_', '')), x)); // temporary: DOWN_WIND -> DOWNWIND //TODO: remove

  for (const feature of vectorCollection?.features || []) {
    const currentRoi = existingRois.findIndex((e) => e.uuid === feature.source);
    if (currentRoi !== -1) {
      roisArr[currentRoi].scanDirection = roisArr[currentRoi].reversed
        ? {
            ...feature.geometry,
            coordinates: (feature.geometry?.coordinates || []).map((e) => e).reverse(),
          }
        : feature.geometry;
    }
  }

  return {
    missionParams: properties.missionParams,
    planeParams: properties.planeParams,
    payloadParams: properties.payloadParams,
    takeoffParams: {
      ...properties.takeoffParams,
      geometry: {
        type: 'Point',
        coordinates: startPoint,
      },
    },
    landingParams: {
      ...properties.landingParams,
      geometry: {
        type: 'Point',
        coordinates: endPoint,
      },
    },
    roiParams: roisArr,
    windParams: {
      directions: weatherInLineString,
    },
  };
};

window.toggleDebugMarkers = () => {
  if (!window.pathFeatures) return;
  if (window.bufferPathMarkers?.length) {
    window.bufferPathMarkers?.forEach((m) => m.removeFrom(window.map));
    window.bufferPathMarkers = [];
  } else {
    window.pathFeatures.map(({ geometry: { coordinates }, properties }) => {
      coordinates.map(([lng, lat], i) => {
        const marker = L.marker(
          { lat, lng },
          {
            textMarker: true,
            text: ` ${i + 1} `,
          }
        );
        marker.on('click', () => console.log(properties.PointProps[i]));
        //marker.bindPopup(`<pr>${[lat, lng].join(',')} <br/> ${JSON.stringify(properties.PointProps[i])}</pr>`, { autoClose: false });
        marker.pm.textArea.className +=
          ' iterative-measurements-marker iterative-measurements-marker-debug';
        marker.addTo(window.map);
        window.bufferPathMarkers.push(marker);
      });
    });
  }
};

const formatResponseFromCpp = async (data, params, cppRequestData) => {
  let waypoints = null;
  try {
    waypoints = await buildWaypoints(data, params, cppRequestData);
  } catch (e) {
    console.error('Error: During the build waypoints file: ', e);
  }

  const featureFootprint = data.features.filter((e) => e.id === IDS_CONST.footprint);

  const pathFeatures = data.features
    .filter((e) => e.id === IDS_CONST.path)
    .map((e) => ({
      ...e,
      geometry: {
        coordinates: e.geometry.coordinates,
        type: 'LineString',
      },
    }));

  const pointOptions = get(pathFeatures, '0.properties.PointProps', []);

  const powerUsed =
    pointOptions && pointOptions.length
      ? get(pointOptions[pointOptions.length - 1], 'MissionCost', 0)
      : 0;
  const routeLengthFromCpp =
    pointOptions && pointOptions.length
      ? get(pointOptions[pointOptions.length - 1], 'MissionLength', 0)
      : 0;

  const newGeoJSONPath = {
    type: 'FeatureCollection',
    id: IDS_CONST.path,
    features: pathFeatures,
    style: PATH_STYLES,
    properties: {
      loiters: pointOptions
        .map(
          (e, index) =>
            e.Kind === 'Loiter' && {
              ...e,
              coordinates: get(pathFeatures, `0.geometry.coordinates.${index}`),
            }
        )
        .filter((e) => e),
    },
  };
  const newGeoJSON = {
    type: 'FeatureCollection',
    id: IDS_CONST.footprint,
    style: FOOTPRINT_STYLES,
    features: featureFootprint,
  };
  const cppToSave = processCPPDataWithMultipolygonSetting(
    [newGeoJSON, newGeoJSONPath],
    !!SAVE_ORIGIN_FOOTPRINT_BY_DEFAULT
  );
  const fpCount = (newGeoJSON?.features || []).reduce((acc, item) => {
    return acc + get(item, 'geometry.coordinates.length', 0);
  }, 0);
  const { length: routeLength } = getGeoJsonMeasurements(newGeoJSONPath);

  if (window.bufferPathMarkers && window.bufferPathMarkers.length) {
    window.bufferPathMarkers.map((e) => {
      try {
        e.remove();
      } catch {}
    });
  }
  window.bufferPathMarkers = [];
  window.pathFeatures = pathFeatures;

  return {
    waypoints,
    cpp: cppToSave,
    fpCount: fpCount || 0,
    routeLength: Number((routeLengthFromCpp || routeLength) / 1000).toFixed(2),
    powerUsed: Number(powerUsed / params.batteryNominalVoltage).toFixed(1),
    pointProps: pointOptions,
  };
};

const getFormattedProperties = (target, startPoint) => {
  const headingGeometry = getVectorGeoJSONbyPointCoords(startPoint, target.headingDeg || 0, 10);

  const missionParamsFns = new Map([
    ['SIMPLE_FLIGHT', (target) => ({
      stripWidthKoeff: target.stripWidthKoeff,
      safetyMargin: target.safetyMargin,
      scanStepForward: target.scanStepForward,
      scanStepSideways: target.scanStepSideways,
      minSafeAltitudeAGL: target.minSafeAltitudeAGL,
      overExtraLength: target.overExtra,
      beginEndLength: target.beginEndLength,
    })],
    ['PHOTOGRAMMETRY', (target) => ({
      gsd: 5,
      gsdMax: 7,
      overlap: {
        side: 60,
        forward: 70
      },
      overlapMin: {
        side: 55,
        forward: 60
      },
      scanStepForward: target.scanStepForward,
      scanStepSideways: target.scanStepSideways,
      minSafeAltitudeAGL: target.minSafeAltitudeAGL,
      overExtraLength: target.overExtra,
      beginEndLength: target.beginEndLength,
    })]
  ])

  const payloadParamsFns = new Map([
    ['SIMPLE_FLIGHT', (target) => ({})],
    ['PHOTOGRAMMETRY', (target) => ({
      frameSizePx: {
        side: 4000,
        forward: 3000
      },
      frameSizeMm: {
        side: 32,
        forward: 24
      },
      focalLength: 50
    })]
  ])

  return {
    missionParams: missionParamsFns.get(target.missionType)(target),
    zoneParamsDef: {
      flightAltitudeAGL: target.H_AGL_Flight,
      lineSpacing: target.LineSpacing,
      constantAltitude: target.ConstantAltitude,
      numberOfPasses: target.numberOfPasses,
      overshootLength: target.L_OvershootLine_Zone,
      LevelingDistance: target.LevelingDistance,
    },
    payloadParams: payloadParamsFns.get(target.missionType)(target),
    landingParams: {
      parachuteDeployAltitudeAGL: target.parachuteDepAltDef,
      loiterRadius: target.LoiterRadius,
      landingMode: target.landingMode,
      approachMode: target.approachMode,
      heading: {
        coordinates: headingGeometry,
        type: 'LineString',
      },
    },

    takeoffParams: {
      loiterRadius: target.helixRadius,
    },
    planeParams: {
      maxAscentGradient: target.AscentGradient,
      maxDescentGradient: target.DescentGradient,
      loiterGradient: target.LoiterGradient,
      loiterRadius: target.LoiterRadius,
      levelingDistance: target.LevelingDistance,
      cruiseAirSpeed: target.CruiseAirSpeed,
      cruisePowerConsumption: target.CruisePowerConsumption,
      optimalAirSpeeds: target.OptimalAirSpeeds,
      overExtra: target.OverExtra,
      beginEnd: target.BeginEnd,
      parachuteDeployAltitudeAGL: target.parachuteDepAltDef,
      approachGradient: target.approachGradient,
      afterParachuteGradient: target.afterParachuteGradient,
      bellyLandingGradient: target.bellyLandingGradient,
      flareLine: target.flareLine,
      bellyLandingInitialAltitude: target.bellyLandingInitialAltitude,
      ...(target.hasParachute
        ? {
            parachuteParams: {
              verticalSpeed: target.verticalSpeed,
              minDeployAltitude: target.minDeployAltitude,
              maxDeployAltitude: target.maxDeployAltitude,
              defDeployAltitude: target.parachuteDepAltDef,
              deployDelay: target.openDelay,
            },
          }
        : {}),
    },
  };
};

const getFormattedPropertiesAerophoto = (target) => {
  return {
    cameraParams: {
      frameSizePx: {
        x: 1, //target.FrameSizePx_x,
        y: 1, //target.FrameSizePx_y,
      },
      frameSizeMm: {
        x: 1, //target.FrameSizeMm_x,
        y: 1, //target.FrameSizeMm_y,
      },
      focalLength: (target.H_AGL_Flight / target.LineSpacing) * 0.4, //target.FocalLength,
    },
    imageParams: {
      gsd: 250 * target.LineSpacing, //target.GSD ,
      overlap: {
        x: 60, //target.Overlap_x,
        y: 60, //target.Overlap_y,
      },
    },
    planeParams: {
      maxAscentGradient: target.AscentGradient,
      maxDescentGradient: target.DescentGradient,
      loiterGradient: target.LoiterGradient,
      loiterRadius: target.LoiterRadius,
      levelingDistance: target.LevelingDistance,
      cruiseAirSpeed: target.CruiseAirSpeed,
      cruisePowerConsumption: target.CruisePowerConsumption,
      optimalAirSpeeds: target.OptimalAirSpeeds,
      overExtra: target.OverExtra,
      beginEnd: target.BeginEnd,
    },
  };
};

const formatWeather = (rawWeatherResponse, targetDatetime) => {
  const keysInResult = Object.keys(rawWeatherResponse?.hourly_units || {}).filter(
    (e) => e !== 'time'
  );

  const weather = rawWeatherResponse?.hourly?.time?.reduce(
    (acc, currentTime, index) => {
      const currentDayKey = moment(currentTime).format('YYYY-DD');
      const currentDay = acc?.byDays[currentDayKey] || {};
      return {
        byDays: {
          ...acc?.byDays,
          [currentDayKey]: {
            ...currentDay,
            ...keysInResult.reduce(
              (acc, key) => ({
                ...acc,
                [`min_${key}`]: Math.min(
                  currentDay[`min_${key}`] || 1000000000000,
                  rawWeatherResponse?.hourly[key][index]
                ),
                [`max_${key}`]: Math.max(
                  currentDay[`max_${key}`] || 0,
                  rawWeatherResponse?.hourly[key][index]
                ),
              }),
              {}
            ),
            byHours: {
              ...(currentDay?.byHours || {}),
              [currentTime]: keysInResult.reduce(
                (acc, key) => ({ ...acc, [key]: rawWeatherResponse?.hourly[key][index] }),
                {}
              ),
            },
          },
        },
      };
    },
    { byDays: {} }
  );

  const weatherAtCurrentDay = weather.byDays[moment(targetDatetime).format('YYYY-DD')]?.byHours;

  const items = Object.keys(weatherAtCurrentDay).map((e) => ({
    date: e,
    value: weatherAtCurrentDay[e],
  }));

  const weatherToSave = items.find(
    ({ date }) => moment(date).format('HH') === moment(targetDatetime).format('HH')
  );

  return {
    items,
    weatherToSave,
    keysInResult,
  };
};

function transformGeoJsonCoordinatesToNumeric(coords) {
  const [startPointCoords, endPointCoords] = coords;
  const startPoint = point(startPointCoords);
  const endPoint = point(endPointCoords);
  return bearing(startPoint, endPoint);
}
function getVectorGeoJSONbyPointCoords([lng, lat], degree, radiusInKm = 10) {
  const startPoint = point([lng, lat]);
  const {
    geometry: { coordinates },
  } = destination(startPoint, radiusInKm * 1000, degree, { units: 'meters' });

  return [startPoint.geometry.coordinates, coordinates];
}
function transformNumericToGeoJsonCoordinates(targetPolygonCoordinates, degree) {
  const polygon = toTuftPolygon(targetPolygonCoordinates, {});
  const {
    geometry: {
      coordinates: [lng, lat],
    },
  } = getCenter(polygon);
  const radiusInKm = 1;
  const startPoint = point([lng, lat]);
  const {
    geometry: { coordinates },
  } = destination(startPoint, radiusInKm * 1000, degree, { units: 'meters' });

  return [startPoint.geometry.coordinates, coordinates];
}
function transformCoordinatesToVectorFeature(coordinates) {
  return {
    type: 'FeatureCollection',
    style: {
      weight: 5,
      dashArray: '10',
    },
    id: IDS_CONST.vector,
    features: [
      {
        type: 'Feature',
        id: IDS_CONST.vector,
        geometry: {
          type: 'LineString',
          coordinates,
        },
      },
    ],
  };
}

function getGeoJsonMeasurements(geoJson) {
  const { LineString, Polygon } = _.groupBy(geoJson.features, (item) => item.geometry.type);

  const result = { area: 0, length: 0 };

  if (LineString) {
    const length = turfLength({
      type: 'FeatureCollection',
      features: LineString,
    });

    result.length = parseFloat(length.toFixed(3));
  }

  if (Polygon) {
    const area = turfArea({
      type: 'FeatureCollection',
      features: Polygon,
    });
    const areaInKilometers = area / 1000000;
    result.area = parseFloat(areaInKilometers.toFixed(3));
  }

  return result;
}
async function takeSnapOfMap(
  map,
  format = 'image',
  options = {
    mimeType: 'image/png',
    domtoimageOptions: {},
  }
) {
  const simpleMapScreenShooter = L.simpleMapScreenshoter({}).addTo(map);
  return await new Promise((res, rej) =>
    simpleMapScreenShooter
      .takeScreen(format, options)
      .then(downScaler)
      .then((_) => res(_))
      .catch((_) => {
        console.error('Unexpected error during the map preview generation, details: ', _);
        res(null);
      })
      .finally(() => {
        simpleMapScreenShooter.remove();
      })
  );
}

const parseOpenWeatherApiResult = (weatherData) => {
  const { time: _, ...current } = weatherData.current;

  const forecast = Object.assign({}, current);

  const formattedTime = moment(_).format('YYYY-MM-DD-HH');

  const currentHour = weatherData.hourly.time.findIndex(
    (time) => moment(time).format('YYYY-MM-DD-HH') === formattedTime
  );

  const { time: h_time, ...hourly } = weatherData.hourly;

  Object.keys(hourly).forEach((hourlyKey) => {
    Object.assign(forecast, { [hourlyKey]: hourly[hourlyKey][currentHour] });
  });

  const assosiateKeysValues = {
    wind_speed_10m: 10,
    wind_speed_80m: 80,
    wind_speed_120m: 120,
    wind_speed_180m: 180,
    windspeed_975hPa: 320,
    windspeed_950hPa: 500,
    windspeed_925hPa: 800,
    windspeed_900hPa: 1000,

    wind_direction_10m: 10,
    wind_direction_80m: 80,
    wind_direction_120m: 120,
    wind_direction_180m: 180,
    winddirection_975hPa: 320,
    winddirection_950hPa: 500,
    winddirection_925hPa: 800,
    winddirection_900hPa: 1000,
  };

  const result = {};

  Object.keys(forecast).forEach((key) => {
    const value = assosiateKeysValues[key];
    const isDirection = key.startsWith('wind_direction_') || key.startsWith('winddirection_');
    const currentNamespace = isDirection ? 'wind_direction' : 'wind_speed';

    Object.assign(result, {
      [value]: {
        ...(result[value] || {}),
        [currentNamespace]: forecast[key],
      },
    });
  });
  return Object.keys(result)
    .filter((e) => Number(e))
    .map((key) => ({ value: Number(key), ...result[key] }));
};

const weatherToCPPRequestType = (startCoords, weatherData) => {
  return weatherData.map(({ value: height, wind_speed, wind_direction }) => {
    const coords = getVectorGeoJSONbyPointCoords(startCoords, wind_direction, wind_speed / 1000);
    return coords.map(([x, y]) => [x, y, height]);
  });
};


const getCenterOfPolygon = ({ type, coordinates }) => {
  if (type === 'Polygon') {
    const polygon = toTuftPolygon(coordinates, {});
    const {
      geometry: {
        coordinates: [lng, lat],
      },
    } = getCenter(polygon);
    return { lat, lng };
  } else if (type === 'LineString') {
    const [lng, lat] = coordinates[Math.floor(coordinates.length / 2)];
    return { lat, lng };
  } else if (type === 'Point') {
    const [lng, lat] = coordinates;
    return { lat, lng };
  }
};
const assignProperties = (data, dataWithNewValues) => {
  const newProperties = Object.assign({}, data.properties || {}, dataWithNewValues.properties);
  return Object.assign({}, data, dataWithNewValues, { properties: newProperties });
};

const featureUpdateByUUID = (uuid, data) => (feature) => {
  if (feature.uuid !== uuid) return feature;
  return data.properties ? assignProperties(feature, data) : Object.assign({}, feature, data);
};

const updateFeatureByIDGroup = (id, data) => (feature) => {
  if (feature.id !== id) return feature;
  return data.properties ? assignProperties(feature, data) : Object.assign({}, feature, data);
};

function convertCoordinateDDToDMS(D, lng) {
  if (!D)
    return {
      deg: null,
      min: null,
      sec: null,
      direction: lng ? 'E' : 'N',
    };
  return {
    direction: D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N',
    deg: 0 | (D < 0 ? (D = -D) : D),
    min: 0 | (((D += 1e-9) % 1) * 60),
    sec: (0 | (((D * 60) % 1) * 6000)) / 100,
  };
}

function convertDDToDMS({ lat, lon }) {
  const newDataDMSLat = convertCoordinateDDToDMS(lat, false);
  const newDataDMSLng = convertCoordinateDDToDMS(lon, true);
  return {
    lat: newDataDMSLat,
    lon: newDataDMSLng,
  };
}

function convertDMSToDD({ lat: latDMS, lon: lonDMS }) {
  const latDeg = parseFloat(latDMS.deg),
    latMin = parseFloat(latDMS.min),
    latSec = parseFloat(latDMS.sec),
    latDir = latDMS.direction,
    lonDeg = parseFloat(lonDMS.deg),
    lonMin = parseFloat(lonDMS.min),
    lonSec = parseFloat(lonDMS.sec),
    lonDir = lonDMS.direction;
  const lat = Number((latDeg + latMin / 60 + latSec / 3600) * (latDir === 'S' ? -1 : 1));
  const lon = Number((lonDeg + lonMin / 60 + lonSec / 3600) * (lonDir === 'W' ? -1 : 1));

  return { lat: Number.isFinite(lat) ? lat : null, lon: Number.isFinite(lon) ? lon : null };
}

function convertDDToDDM({ lat, lon }) {
  const latAbs = Math.abs(lat);
  const latDirection = lat < 0 ? 'S' : 'N';
  const latDeg = Math.trunc(latAbs);
  const latDecimalMin = parseFloat(parseFloat(`${(latAbs - latDeg) * 60}`));
  const lngAbs = Math.abs(lon);
  const lngDirection = lon < 0 ? 'W' : 'E';
  const lngDeg = Math.trunc(lngAbs);
  const lngDecimalMin = parseFloat(parseFloat(`${(lngAbs - lngDeg) * 60}`));

  return {
    lat: {
      deg: latDeg,
      decimalMin: latDecimalMin,
      direction: latDirection,
    },
    lon: {
      deg: lngDeg,
      decimalMin: lngDecimalMin,
      direction: lngDirection,
    },
  };
}

function convertDDMToDD({ lat, lon }) {
  const latInv = lat.direction === 'S' ? -1 : 1;
  const latDD = Number(parseFloat((lat.deg + lat.decimalMin / 60) * latInv));
  const lngInv = lon.direction === 'W' ? -1 : 1;
  const lngDD = Number(parseFloat((lon.deg + lon.decimalMin / 60) * lngInv));

  return { lat: Number.isFinite(latDD) ? latDD : null, lon: Number.isFinite(lngDD) ? lngDD : null };
}
// Default GeoJSON
function getFormattedFeatureType(feature) {
  const type = feature?.geometry?.type;
  switch (type) {
    case 'Point':
      return type;
    case 'Polygon':
      return 'Area';
    case 'LineString':
      return 'Path';
    default:
      return 'IncorrectType';
  }
}
// Custom Items JSON;
function getFormattedFeatureTypeExport(feature) {
  const type = feature?.geometry?.type;
  switch (type) {
    case 'Point':
      return type;
    case 'Polygon':
      return 'Area';
    case 'LineString':
      return 'Line';
    default:
      return 'IncorrectType';
  }
}

function getIconWithText(text = '', iconSrc = '/map/icons/default-icon.png', className) {
  return L.divIcon({
    iconSize: [25, 32],
    iconAnchor: [13, 35],
    html: `
      <div class="custom-marker-container">
        <img src="${iconSrc}" style="width:100%;">
        <div class="custom-marker-text ${className || ''}">${text}</div>
      <div>`,
  });
}

function convertDegree(deg, precision = 0) {
  if (_.isNil(deg) || !Number.isFinite(Number(deg))) return deg;
  return ((Number(deg) + 360) % 360).toFixed(precision);
}
export {
  assignProperties,
  featureUpdateByUUID,
  updateFeatureByIDGroup,
  convertHeightsRespToChartData,
  formatKMLForGoogleEarth,
  processCPPDataWithMultipolygonSetting,
  convertGeoJsonDataToFlightPlanerRequestType,
  getFormattedProperties,
  formatResponseFromCpp,
  formatWeather,
  getGeoJsonMeasurements,
  transformGeoJsonCoordinatesToNumeric,
  transformNumericToGeoJsonCoordinates,
  transformCoordinatesToVectorFeature,
  processInputCalculatorGeoJSON,
  convertPathToChartData,
  getVectorGeoJSONbyPointCoords,
  takeSnapOfMap,
  parseOpenWeatherApiResult,
  weatherToCPPRequestType,
  getCenterOfPolygon,
  convertDDToDMS,
  convertDMSToDD,
  convertDDToDDM,
  convertDDMToDD,
  getFormattedFeatureType,
  getIconWithText,
  convertDegree,
  getFormattedFeatureTypeExport,
};
