<template>
  <VCoordinatesModal title="Set target point by coordinates" :is-visible="isVisible" @success="handleSuccess" @close="handleClose"
                     :initValues="[]">
    <template #bodyStart>
      <VSelect v-model="pointType" :search-option="true" :options="options" class="mb" placeholder="Select threat unit" />
    </template>
  </VCoordinatesModal>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex'
import VCoordinatesModal from '@/components/modals/components/VCoordinatesModal';
import VSelect from '@/components/ui/VSelect.vue';

export default defineComponent({
  components: { VCoordinatesModal, VSelect },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      options: ({ params: { pointsProfiles, missionProfiles, missionProfile: currentProfileID }}) =>  {
        const missionProfile = missionProfiles.find(({ID}) => ID === currentProfileID);
        return pointsProfiles
            .filter(pointsProfile => !(missionProfile && missionProfile.type) || missionProfile.type === pointsProfile.type)
            .map(profile => ({ value: profile.ID, label: profile.label }))
      },
    })
  },
  methods: {
    handleSuccess(coordinates) {
      if(this.pointType && coordinates) {
        this.$store.dispatch('targetPoints/saveTargetPoint', { pointType: this.pointType, coordinates: [coordinates.lon, coordinates.lat] });
      }
    },
    handleClose() {
      this.$store.commit('targetPoints/resetTargetPoint');
    }
  },
  data() {
    return {
      pointType: null,
      coordinates: []
    }
  }
})
</script>
<style scoped>
.mb {
  margin-bottom: 12px;
}
</style>