<template>
  <VModal
    :is-visible="isVisible"
    title="Photogrammetry parameters"
    :title-icon="EditIcon"
    size="mrlarge"
    ok-text="Apply"
    @close="handleClose"
    @success="handleClose"
    @keydown:escape="handleClose"
  >
    <template #body>
      <div class="photometryModal">
        <div class="photometrySection">
          <div class="left">
            <div class="portraitWrapper">
              <VCheckbox v-model="portraitOrientation"
                         :disabled="isPayloadEmpty"
                         label="Portrait" />
              <VSelect
                v-model="payloadProfileModel"
                :search-option="true"
                :key="payloadProfileOptions"
                :options="payloadProfileOptions"
                class="selectPortrait"
                placeholder="Select payload"
              />
            </div>
          </div>
          <div class="right">
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              label-text="Focal Length [mm]"
              :disabled="isPayloadEmpty"
              v-model="focalLengthDef"
            />
          </div>
        </div>

        <div class="photometrySection">
          <div class="left">
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              label-text="GSD [cm/px]"
              v-model="gsdDef"
            />
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              label-text="FWD Overlap [%]"
              v-model="forwardOverlapDef"
            />
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              label-text="Side Overlap [%]"
              v-model="sideOverlapDef"
            />
          </div>
          <div class="right">
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              v-model="gsdMax"
              label-text="GSD MAX [cm/px]"
            />
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              v-model="forwardOverlapMin"
              label-text="FWD Overlap MIN [%]"
            />
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              v-model="sideOverlapMin"
              label-text="Side Overlap MIN [%]"
            />
          </div>
        </div>

        <div class="photometrySection">
          <div class="left">
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              v-model="extraPhotosDef"
              label-text="Extra Photos [pcs]"
            />
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              v-model="overshootZoneDef"
              label-text="Zone Overshoot [m]"
            />
            <VInputNumber
              class="photoField"
              input-class-name="inputPhoto"
              label-text="Route Overshoot [m]"
            />
          </div>
          <div class="right">
            <VCheckbox label="Constant Altitude" v-model="altDef" />
            <div class="selectLabel">
              <div class="labelPhoto">GSD mode</div>
              <VSelect
                :disabled="!isAttitudeChecked"
                v-model="gsdMode"
                :options="gsdModeOptionsList"
                class="modeVSelect"
                placeholder="GSD mode"
              />
            </div>
            <div class="selectLabel">
              <div class="labelPhoto">Overlap mode</div>
              <VSelect
                :disabled="!isAttitudeChecked"
                v-model="overlapMode"
                :options="overlapModeOptionsList"
                class="modeVSelect"
                placeholder="Select Overlap Mode"
              />
            </div>
          </div>
        </div>

        <div class="photometrySection">
          <div class="left">
            <VInputNumber
              class="photoField"
              v-model="opticalHeight"
              disabled
              input-class-name="inputPhoto"
              label-text="Optical Height [m]"
            />
          </div>
          <div class="right">
            <VInputNumber
              class="photoField"
              disabled
              v-model="opticalWeight"
              input-class-name="inputPhoto"
              label-text="Optical Width [m]"
            />
          </div>
        </div>
      </div>
    </template>
  </VModal>
</template>

<script>
import {computed, defineComponent, watch} from 'vue';
import {mapState, useStore} from 'vuex';
import VSelect from '@/components/ui/VSelect.vue';
import VModal from '@/components/ui/VModal.vue';
import { EditIcon } from '@/assets/svgs';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import { VInputNumber } from '@/components/ui';
import {calculateOpticalParams, useMappingStoreParams, useProfilesSelect} from "@/utils/helpers";

export default defineComponent({
  components: { VInputNumber, VCheckbox, VModal, VSelect },
  setup() {
    const store = useStore();
    const {
      sensorWideSizePx,
      sensorNarrowSizePx,
      portraitOrientation,
      focalLengthDef,
      gsdDef,
      gsdMax,
      forwardOverlapDef,
      forwardOverlapMin,
      sideOverlapDef,
      sideOverlapMin,
      extraPhotosDef,
      overshootRouteDef,
      overshootZoneDef,
      altDef,
      gsdMode,
      overlapMode
    } = useMappingStoreParams([
      'sensorWideSizePx',
      'sensorNarrowSizePx',
      'portraitOrientation',
      'focalLengthDef',
      'gsdDef',
      'gsdMax',
      'forwardOverlapDef',
      'forwardOverlapMin',
      'sideOverlapDef',
      'sideOverlapMin',
      'extraPhotosDef',
      'overshootRouteDef',
      'overshootZoneDef',
      'altDef',
      'gsdMode',
      'overlapMode'
    ]);

    const { fieldData, fieldSelectedModel } = useProfilesSelect(
        'payloadProfile',
        'payloadProfiles',
        'params/setPayloadProfile'
    );

    const gsdModeOptionsList = computed(() => store.state.params.gsdModeOptionsList || []);
    const overlapModeOptionsList = computed(() => store.state.params.overlapModeOptionsList || []);

    const isPayloadEmpty = computed(() => !fieldSelectedModel.value);
    const isAttitudeChecked = computed(() => !!altDef.value);

    const isVisible = computed(() => store.state.params.isPhotogrammetryGlobalParamsVisible);

   const handleClose = () => store.commit('params/setIsPhotogrammetryGlobalParamsVisible', false)

    const opticalWeight = computed(() => calculateOpticalParams(gsdDef.value, sensorWideSizePx.value));
    const opticalHeight = computed(() => calculateOpticalParams(gsdDef.value, sensorNarrowSizePx.value));
    return {
      // Function data;
      EditIcon,

      // Methods
      handleClose,

      // Flags
      isVisible,
      isPayloadEmpty,
      isAttitudeChecked,

      // Options for selects;
      payloadProfileOptions: fieldData,
      gsdModeOptionsList,
      overlapModeOptionsList,

      // immutable
      opticalHeight,
      opticalWeight,

      // Values in Vue models (v-model="...") format;
      payloadProfileModel: fieldSelectedModel,
      portraitOrientation,
      focalLengthDef,
      gsdDef,
      gsdMax,
      forwardOverlapDef,
      forwardOverlapMin,
      sideOverlapDef,
      sideOverlapMin,
      extraPhotosDef,
      overshootZoneDef,
      overshootRouteDef,
      altDef,
      gsdMode,
      overlapMode
    }
  }
});
</script>
<style lang="scss" scoped>
.photometryModal {
  .photometrySection {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #7d7d91;
    &:last-child {
      border: 0;
    }
    .left {
      width: 50%;
      padding: 12px 6px;
      .portraitWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .selectPortrait {
          width: 152px;
        }
      }
    }
    .right {
      width: 50%;
      padding: 12px 8px;
    }
    .selectLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 12px 0;
      &:last-child {
        margin-bottom: 0;
      }
      .labelPhoto {
        color: #dadae8;
        line-height: 14px;
        font-size: 12px;
        font-weight: 400;
      }
      .modeVSelect {
        width: 149px;
      }
    }
    .photoField {
      color: #dadae8;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      margin: 12px 0;
      &:first-child,
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>