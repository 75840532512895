import service from '@/services/service';
import L from 'leaflet';
import _ from 'lodash';
const photogrammetryFields = {
  // payload fields
  portraitOrientation: false,
  focalLengthDef: 0,
  payloadProfile: null,

  gsdDef: 0,
  gsdMax: 0,
  forwardOverlapDef: 0,
  forwardOverlapMin: 0,
  sideOverlapDef: 0,
  sideOverlapMin: 0,
  extraPhotosDef: 0,
  overshootRouteDef: 0,
  overshootZoneDef: 0,
  altDef: false,
  gsdMode: null,
  overlapMode: null
}
/**
 * @UB<Plain_data_record> Data all params inside will get/set in DB;
 * @type {{footprintCount: number, LoiterGradient: number, takeoffMode: string, powerUsed: number, LineSpacing: number, color: string, Overlap_x: number, FrameSizeMm_y: number, Overlap_y: number, FrameSizeMm_x: number, helixRadius: number, latitude_start: null, waypoints: string, approachMode: string, airSpeed: number, AscentGradient: number, isSimpleFlight: boolean, plain: null, GSD: number, longitude_end: null, L_OvershootLine_Zone: number, ID: null, camera: null, longitude_start: null, latitude_end: null, FrameSizePx_y: number, area: number, takeoffAltMin: number, airVector: number, headingDeg: number, scanMode: string, parachuteDepAltDef: number, ConstantAltitude: boolean, cppRouteLength: number, powerCount: number, FocalLength: number, LoiterRadius: number, H_AGL_Flight: number, missionProfile: null, name: string, mi_modifyDate: null, weatherCondition: string, landingMode: null, CruiseAirSpeed: number, LevelingDistance: number, startDate: null, FrameSizePx_x: number, DescentGradient: number}}
 */
const initState = {
  FrameSizePx_x: 6000,
  FrameSizePx_y: 8000,
  FrameSizeMm_x: 6,
  FrameSizeMm_y: 5,
  FocalLength: 10,
  ...photogrammetryFields,
  LevelingDistance: null,
  GSD: 20,
  Overlap_x: 80,
  Overlap_y: 60,

  LoiterRadius: 25,

  powerUsed: 0,
  isSimpleFlight: true,
  LineSpacing: null,
  ConstantAltitude: false,
  H_AGL_Flight: null,

  // System field:
  mi_modifyDate: null,

  ID: null,
  externalID: null,
  isReadOnly: false,
  plain: null,
  camera: null,
  missionProfile: null,
  name: '',
  color: '',
  longitude_start: null,
  latitude_start: null,
  longitude_end: null,
  latitude_end: null,
  area: 0,
  cppRouteLength: 0,
  footprintCount: 0,
  airSpeed: 0,
  airVector: 0,
  waypoints: '',
  startDate: null,
  weatherCondition: '',

  // ----------- client enums default
  scanMode: 'UPWIND',
  approachMode: 'UPWIND',
  takeoffMode: 'SLOW_ASCEND',
  landingMode: null,
  //----------------
  headingDeg: 0,
  powerCount: 0,
  takeoffAltMin: 30,
  helixRadius: 30,
  parachuteDepAltDef: 0,
  L_OvershootLine_Zone: null,
};
function initialState() {
  return {
    // Extend initState to track and save option param;
    ...initState,

    // All params below will not be automatically saving...;
    // If you need to save some params from list just move them to initState, after that it will tack & save;
    isTerracing: false,
    isRouteFP: false,
    plains: [],
    cameras: [],
    missionProfiles: [],
    airSpeed1000: 0,
    airVector1000: 0,
    OptimalAirSpeeds: [],

    pointsProfiles: [],

    payloadProfiles: [],

    importInProcess: false,
    // Enums from DB;
    landingModeOptionsList: [],
    scanModeOptionsList: [],
    routeEntry: 'NEAREST',
    routeEntryOptionsList: [
      { value: 'NEAREST', label: 'Nearest' },
      { value: 'FURTHEST', label: 'Furthest' },
    ],
    takeoffModeOptionsList: [],
    approachModeOptionsList: [],
    gsdModeOptionsList: [],
    overlapModeOptionsList: [],

    isAdvancedZoneParamsVisible: false,
    isPhotogrammetryGlobalParamsVisible: false,

    parachuteDepAltDefDisabled: false,
    powerUsedDisabled: false,
    numberOfPasses: 2,

    AscentGradient: 25,
    DescentGradient: 25,
    CruiseAirSpeed: 25,
    LoiterGradient: 25,
    // toDo:
    isDraft: false,

    // plane params
    approachGradient: null,
    bellyLandingGradient: null,




    payloadProfile: null
  };
}
// Getter functions
const getters = {
  isPhotogrammetry(state) {
    const currentProfile = state.missionProfiles.find(({ ID }) => state.missionProfile === ID);
    return currentProfile && currentProfile.missionType === "PHOTOGRAMMETRY"
  },
  getWeatherConditions(state) {
    try {
      return JSON.parse(state.weatherCondition);
    } catch (e) {
      console.error('Error during parsing the weather condition!');
      return null;
    }
  },
  getTargetPointData(state) {
    return state.pointsProfiles.reduce((acc, item) => ({ ...acc, [item.ID]: item }), {});
  },
  getPointTypeOptions(state) {
    return state.pointsProfiles.map((profile) => ({ value: profile.ID, label: profile.label }));
  },
  getStartPointCoordinates(state) {
    const startCoords = [state.latitude_start, state.longitude_start];
    return startCoords.every((e) => Number(e)) && startCoords;
  },
  getEndPointCoordinates(state) {
    const endCoords = [state.latitude_end, state.longitude_end];
    return endCoords.every((e) => Number(e)) && endCoords;
  },
  getParamsAll(state) {
    return Object.keys(initState).reduce((values, key) => ({ ...values, [key]: state[key] }), {});
  },
  getCurrentPlainData(state) {
    return state.plains.find(({ ID }) => ID === state.plain);
  },
};
// Actions
const actions = {
  async getCorrelationsList({ state, commit }) {
    if (!state.plain) return;
    const OptimalAirSpeeds = await service.getCorrelationByPlainProfileID(state.plain);
    commit('getCorrelationsSuccess', OptimalAirSpeeds);
  },
  async getPlainsList({ state, commit }) {
    const data = await service.getPlains();
    commit('getPlainsListSuccess', data);
    if (state.plain) commit('setPlain');
  },
  async getCameraList({ state, commit }) {
    const data = await service.getCamera();
    commit('getCamerasListSuccess', data);
    if (state.camera) commit('setCamera', state.camera);
  },
  async getMissionProfileList({ state, commit }) {
    const data = await service.getMissionProfiles();
    commit('getMissionProfileListSuccess', data);
    if (state.missionProfile) commit('setMissionProfile');
  },
  async getPayloadProfiles({ commit, state }) {
    const data = await service.getPayloadProfiles();
    commit('getPayloadProfileListSuccess', data);
    if(state.payloadProfile) commit('setPayloadProfile');
  },
  async getPointsProfileList({ commit }) {
    const data = await service.getPointsProfileList();
    commit('getPointsProfileListSuccess', data);
  },
  async licenseCheck() {
    const data = await service.getLicenseActiveList();
    if (!data.length)
      alert('You don`t have or your license has been deactivated. Contact your service provider!');
    return !!data.length;
  },
  async getEnumsOptions({ commit }) {
    const landingModeOptionsList = await service.getEnumsOptionsByGroupCode('FP_LANDING_MODE');
    const scanModeOptionsList = await service.getEnumsOptionsByGroupCode('FP_SCAN_MODE');
    const takeoffModeOptionsList = await service.getEnumsOptionsByGroupCode('FP_TAKEOF_MODE');
    const approachModeOptionsList = await service.getEnumsOptionsByGroupCode('FP_APROACH_MODE');
    const gsdModeOptionsList = await service.getEnumsOptionsByGroupCode('FP_GSD_MODES');
    const overlapModeOptionsList = await service.getEnumsOptionsByGroupCode('FP_OVERLAP_MODES');

    // feel free to extend this one;
    commit('setEnumOptionsList', {
      landingModeOptionsList,
      scanModeOptionsList,
      takeoffModeOptionsList,
      approachModeOptionsList,
      gsdModeOptionsList,
      overlapModeOptionsList
    });
  },
  async fetchProfiles({ commit, dispatch, state }) {
    commit('setFetching', true, { root: true });
    try {
      // Looking for active licensee;
      const haveLicense = await dispatch('licenseCheck');

      // If don`t have stopping fetch;
      if (!haveLicense) return commit('setFetching', false, { root: true });

      //ToDO: Fix me:
      // ID loosing in case when getPlainsList and getMissionProfileList together use setter;
      const ID = state.ID;
      // Getting all profiles;
      await dispatch('getEnumsOptions');
      await dispatch('getCameraList');
      await dispatch('getPlainsList');
      await dispatch('getMissionProfileList');
      await dispatch('getCorrelationsList');
      await dispatch('getPointsProfileList');
      await dispatch('getPayloadProfiles');

      state.ID = ID;
      commit('setFetching', false, { root: true });
    } catch (e) {
      console.error(e);
      commit('setFetching', false, { root: true });
    }
  },
};
// Mutations
const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setAdvancedZoneParamsVisible(state, isVisible) {
    state.isAdvancedZoneParamsVisible = isVisible;
  },
  setIsPhotogrammetryGlobalParamsVisible(state, isVisible) {
    state.isPhotogrammetryGlobalParamsVisible = isVisible;
  },
  setFormValue(state, { key, value }) {
    //if(key in initState) {
    state[key] = value;
    state.isDraft = true;
    //}
  },
  setEnumOptionsList(state, values) {
    Object.keys(values || {}).forEach((key) => {
      state[key] = values[key].map(({ code, name }) => ({ value: code, label: name }));
    });
  },
  getCorrelationsSuccess(state, OptimalAirSpeeds) {
    state.OptimalAirSpeeds = OptimalAirSpeeds;
  },
  setCalculationResults(state, { fpCount, waypoints, routeLength, powerUsed }) {
    state.footprintCount = fpCount;
    state.waypoints = waypoints;
    state.cppRouteLength = routeLength;
    state.powerUsed = powerUsed;
  },
  getPlainsListSuccess(state, plains) {
    state.plains = plains;
  },
  getCamerasListSuccess(state, cameras) {
    state.cameras = cameras;
  },
  getMissionProfileListSuccess(state, missionProfiles) {
    state.missionProfiles = missionProfiles;
  },
  getPointsProfileListSuccess(state, pointProfiles) {
    state.pointsProfiles = pointProfiles;
  },
  getPayloadProfileListSuccess(state, payloadProfiles) {
    state.payloadProfiles = payloadProfiles
  },
  resetPlain(state) {
    state.plain = null;
    if (state.plains?.[0]) {
      const initState = initialState();
      Object.keys(state.plains?.[0]).map(
        (keyToChange) => (state[keyToChange] = initState[keyToChange])
      );
    }
  },
  mergePlain(state, { ID: value, ...rest }) {
    const ID = value || state.plain;
    const isInitSetting = !value;

    let plainData = Object.assign(
      {},
      state.plains.find((e) => e.ID === ID)
    );

    if (_.isEmpty(plainData)) {
      state.plain = null;
      return;
    }

    const restPlain = Object.keys(plainData).reduce((acc, key) => {
      return rest[key] ? { ...acc, [key]: rest[key] } : acc;
    }, {});
    plainData = { ...plainData, ...restPlain };

    delete plainData.ID;
    state.plain = ID;
    Object.keys(plainData).map(
      (keyToChange) =>
        (state[keyToChange] = (isInitSetting && state[keyToChange]) || plainData[keyToChange])
    );
  },
  setPlain(state, value) {
    const ID = value || state.plain;
    const isInitSetting = !value;

    const plainData = Object.assign(
      {},
      state.plains.find((e) => e.ID === ID)
    );

    if (_.isEmpty(plainData)) {
      state.plain = null;
      return;
    }

    delete plainData.ID;
    state.plain = ID;
    Object.keys(plainData).map(
      (keyToChange) =>
        (state[keyToChange] = (isInitSetting && state[keyToChange]) || plainData[keyToChange])
    );
  },
  setPayloadProfile(state, value) {
    const ID = value || state.payloadProfile;
    const isInitSetting = !value;

    const plainData = Object.assign(
        {},
        state.payloadProfiles.find((e) => e.ID === ID)
    );

    if (_.isEmpty(plainData)) {
      state.payloadProfile = null;
      return;
    }

    delete plainData.ID;
    state.payloadProfile = ID;
    Object.keys(plainData).map(
        (keyToChange) =>
            (state[keyToChange] = (isInitSetting && state[keyToChange]) || plainData[keyToChange])
    );
  },
  setCamera(state, ID) {
    const cameraData = Object.assign({}, state.cameras.find((e) => e.ID === ID) || {});
    delete cameraData.ID;

    state.camera = ID;
    Object.keys(cameraData).map((keyToChange) => (state[keyToChange] = cameraData[keyToChange]));
  },
  setMissionProfile(state, value) {
    const ID = value || state.missionProfile;
    const isInitSetting = !value;

    const missionProfileData = Object.assign(
      {},
      state.missionProfiles.find((e) => e.ID === ID) || {}
    );
    delete missionProfileData.ID;

    state.missionProfile = ID;
    Object.keys(missionProfileData).map(
      (keyToChange) => (state[keyToChange] =  (isInitSetting && state[keyToChange]) || missionProfileData[keyToChange])
    );
  },
  setStart(state, [lat, lng]) {
    state.latitude_start = lat;
    state.longitude_start = lng;
  },
  setFinish(state, [lat, lng]) {
    state.latitude_end = lat;
    state.longitude_end = lng;
  },
  initPramsState(state, data) {
    Object.keys(initState).forEach((key) => {
      if (key === 'ID') {
        state[key] = data[key];
      }
      state[key] = data[key] || state[key];
    });
  },
  setImportProcess(state, value) {
    state.importInProcess = value;
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
