import iconByUnitCategory from './threatZoneIcon'
import '@/plugins/leaflet-donut/L.Donut.js'

export class ThreatZone {
  constructor(zoneData) {
    this.zoneData = zoneData
    this.height = zoneData.height ?? 0
    this.layer = []
  }

  addTo(parent) {
    this.parent = parent
    this.layer && this.layer.addTo(parent)
  }

  removeFrom(parent) {
    this.layer && this.layer?.removeFrom?.(parent)
  }

  checkLayerByOption({ option, optionValue }) {
    return this.layer?.options?.zoneData?.[option] === optionValue
  }

  setVisibility(isVisible) {
    this.layer.options.hidden = !isVisible
    const shouldBeVisible = isVisible ? this.update(this.layer, this.height) : false
    this.layer.getElement().style.display = shouldBeVisible ? '' : 'none'
  }

  setVisibilityByOption({ option, optionValue, isVisible = true }) {
    if (this.checkLayerByOption({ option, optionValue })) {
      this.setVisibility(isVisible)
    }
  }

  getRanges(options, height) {
    let newMinRange = this.calcRadiusByHeight(options?.zoneData?.minRange, height)
    let newMaxRange = this.calcRadiusByHeight(options?.zoneData?.maxRange, height)

    return [newMinRange, newMaxRange]
  }

  update(layer, height) {
    if (layer?.options?.zoneData?.threatKind === 'OBJECT') return true

    let [newMinRange, newMaxRange] = this.getRanges(layer?.options, height)

    if (newMinRange === newMaxRange) {
      if (!layer.options.hidden) {
        layer.options.heightHidden = true
        this.setVisibility(false)
        return false
      }
    } else {
      layer?.setInnerRadius?.(newMinRange)
      layer?.setRadius?.(newMaxRange)
      if (layer.options.heightHidden) {
        layer.options.heightHidden = false
        this.setVisibility(true)
      }
    }
    return true
  }

  setHeight(height) {
    if (height < 0) {
      console.error('Height should be a positive value')
      return
    }
    this.height = height
    this.update(this.layer, height)
  }

  build(height = 0) {
    if (this.zoneData.threatKind !== "OBJECT") {
      this.layer = this.buildZone(this.zoneData, height)
    } else {
      this.layer = this.getMarkerByUnitCategory(this.zoneData)
    }
    return this
  }

  getMarkerByUnitCategory(zoneData) {
    const icon = L.icon({
      iconUrl: zoneData.iconDataUrl || 'map/icons/threat.svg',
      iconSize: [24, 24]
    })
    return L.marker(
      zoneData.coordinates, 
      { ...(icon ? { icon } : {}), zoneData }
    ).bindTooltip(zoneData.unitName || `[${zoneData.unitCode}]`, { direction: 'top' })
  }

  buildZone(zoneData, height) {
    let { threatKind, minRange, maxRange, coordinates } = zoneData
    const style = this.getStyleByThreatKind(threatKind)

    if (height) {
      minRange = this.calcRadiusByHeight(minRange, height)
      maxRange = this.calcRadiusByHeight(maxRange, height)
    }

    if (!minRange && !maxRange) return null
    return L.donut(coordinates, {
      radius: maxRange,
      innerRadius: minRange,
      innerRadiusAsPercent: false,
      zoneData,
      ...style,
    })
  }

  calcRadiusByHeight(radius, height) {
    if (!height) return radius
    if (height >= radius) return 0
    return Math.round(Math.sqrt(Math.pow(radius, 2) - Math.pow(height, 2)))
  }

  getStyleByThreatKind(threatKind) {
    if (threatKind === 'MISSILE') {
      return { color: 'red', weight: 1, fillOpacity: 0.1 }
    }
    if (threatKind === 'CANNON') {
      return { color: 'yellow', fillOpacity: 0.8, weight: 1 }
    }
    if (threatKind === 'RADAR') {
      return { color: '#3388ff', weight: 1, fillOpacity: 0.1 }
    }
    return {}
  }
}

export function threatZone(zoneData) {
  return new ThreatZone(zoneData).build(zoneData.height)
}

window.threatZone = threatZone