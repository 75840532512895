<script setup>
import VCoordinatesModal from '@/components/modals/components/VCoordinatesModal';
import VThreatCoordinatesModal from '@/components/modals/components/VThreatCoordinatesModal';
import VThreatMapCoordinatesModal from '@/components/modals/components/VThreatMapCoordinatesModal';
import VImport from '@/components/modals/import/VImport';
import VSaveMissionModal from '@/components/modals/components/VSaveMissionModal';
import VFilesModal from '@/components/modals/mission-manager/VFilesModal.vue';
import VDataInvalidModal from '@/components/modals/components/VDataInvalidModal';
import VTargetPointMapCoordinatesModal from '@/components/modals/components/VTargetPointMapCoordinatesModal';
import useGlobalEvents from '@/hooks/useGlobalEvents';
import useMapEvents from '@/hooks/useMapEvents';
import useExport from '@/hooks/useExport';
import { useStore } from 'vuex';
import { KmzBuilder } from '@/lib/kmz-builder';
import VExportModal from '@/components/modals/export/VExportModal.vue';
import VMissionCombinerModal from '@/components/modals/mission-manager/VMissionCombinerModal';
import { computed } from 'vue';
import VTargetPointCoordinatesModal from '@/components/modals/components/VTargetPointCoordinatesModal';
import VThreatDangerSectorModal from '@/components/modals/components/VThreatDangerSectorModal.vue';
import VAdvancedZoneParams from "@/components/modals/components/VAdvancedZoneParams";
import VPhotogrammetryParamsModal from "@/components/modals/components/VPhotogrammetryParamsModal.vue";


const store = useStore();
/**
 * @Here presented root modals connector
 * if you need to add modal feel free to add here;
 */
const startCoords = computed(() => store.getters['params/getStartPointCoordinates']);
const endCoords = computed(() => store.getters['params/getEndPointCoordinates']);
const isTargetPointByMapVisible = computed(
  () => store.getters['targetPoints/targetPointByMapDrawModalVisible']
);
const isTargetPointByCoordinatesVisible = computed(
  () => store.state.targetPoints.isTargetPointByCoordinateVisible
);

const {
  isCurrentGeoLocationSetting,
  isThreatByCoord,
  editingThreat,
  currentThreatCoordinates,
  onRevertSettingCurrentGeoLocation,
  onSetThreatByCoords,
  setCurrentThreatCoordinates,
  currentDangerSectorCoordinates,
  setCurrentDangerSectorCoordinates,
  onSaveCurrentGeoLocation,
  onSaveClose,
  onImportClick,
  onExportClick,
  onSaveAsClick,
  onLogoutClick,
  onImportFilesUploaded,
  importProps,
  importAllMissionFromFile,
  isSaveVisible,
  isSaveAs,
  onSaveClick: onSaveClickSuccess,
  onExportClickSuccess,
  onSaveThreatLocation,
} = useGlobalEvents({ useOptimizeMode: false });
const {
  drawPolygon,
  drawThreat,
  isDrawing,
  showCurrentGeoLocation,
  setVisibleCoordsModal,
  isCoordsModalVisible,
  setTakeOffVisibleCoordsModal,
  setLandingVisibleCoordsModal,
  isTakeOffEditCoodrsModalVisible,
  isLandingEditCoodrsModalVisible,
  drawCustomPointByCoords,
  onEditTakeOffCoords,
  onEditLandingCoords,
  customPointEditingName,
  onCustomPointEditCancel,
  onCustomPointEditSuccess,
  isCustomPointVisible,
} = useMapEvents();
const { rows, isExportModalVisible, setVisibleExportModal, onExportClickClose } = useExport([
  'rows',
  'list',
  'isExportModalVisible',
  'setVisibleExportModal',
]);

const handleSaveThreatLocation = (event) => {
  const { createNew, ...rest } = event || {};
  onSaveThreatLocation(rest);
  if (event) {
    createNew && drawThreat();
  }
};
</script>
<template>
  <div>

    <VAdvancedZoneParams/>
    <VPhotogrammetryParamsModal/>

    <VTargetPointCoordinatesModal :is-visible="isTargetPointByCoordinatesVisible" />
    <VTargetPointMapCoordinatesModal :is-visible="isTargetPointByMapVisible" />
    <VSaveMissionModal
      :is-visible="isSaveVisible"
      :is-save-as="isSaveAs"
      @save="onSaveClickSuccess($event)"
      @close="onSaveClose()"
    />
    <VImport
      :on-import-files-uploaded="onImportFilesUploaded"
      :import-data="importProps"
      @missionImportAll="importAllMissionFromFile"
    />
    <VExportModal
      :data="rows || []"
      :is-visible="isExportModalVisible"
      @success="onExportClickSuccess"
      @close="onExportClickClose"
    />
    <VCoordinatesModal
      :init-values="startCoords"
      title="Edit TakeOff coordinates"
      :is-visible="isTakeOffEditCoodrsModalVisible"
      @success="onEditTakeOffCoords($event)"
      @close="setTakeOffVisibleCoordsModal"
    />
    <VCoordinatesModal
      :init-values="endCoords"
      title="Edit Landing coordinates"
      :is-visible="isLandingEditCoodrsModalVisible"
      @success="onEditLandingCoords($event)"
      @close="setLandingVisibleCoordsModal"
    />
    <VCoordinatesModal
      title="Set point by coordinates"
      :is-visible="isCoordsModalVisible"
      @success="drawCustomPointByCoords($event)"
      @close="setVisibleCoordsModal()"
    />
    <VCoordinatesModal
      title="Set location by coordinates"
      :is-visible="isCurrentGeoLocationSetting"
      @success="onSaveCurrentGeoLocation($event)"
      @close="onRevertSettingCurrentGeoLocation()"
    />
    <VThreatCoordinatesModal
      :title="editingThreat ? 'Edit threat' : 'Set location by coordinates'"
      :init-values="editingThreat"
      :is-visible="isThreatByCoord"
      @success="onSaveThreatLocation"
      @close="() => onSetThreatByCoords(false)"
    />
    <VThreatMapCoordinatesModal
      title="Set location on map"
      :current-threat-coordinates="currentThreatCoordinates"
      :is-visible="currentThreatCoordinates"
      @success="handleSaveThreatLocation"
      @close="() => setCurrentThreatCoordinates(null)"
    />
    <VThreatDangerSectorModal
      :is-visible="currentDangerSectorCoordinates"
      @success="(e) => console.log(e)"
      @close="() => setCurrentDangerSectorCoordinates(null)"
    />
    <VCoordinatesModal
      :title="customPointEditingName"
      :is-visible="isCustomPointVisible"
      @success="onCustomPointEditSuccess($event)"
      @close="onCustomPointEditCancel()"
    />
    <VFilesModal />
    <VDataInvalidModal />
    <VMissionCombinerModal />
  </div>
</template>
<style scoped></style>