<template>
  <div>
    <label v-if="labelText" :for="name">{{ labelText }}</label>
    <el-input-number
      :class="{ 'input-num': true, [inputClassName]: inputClassName }"
      v-bind="$attrs"
      :placeholder="placeholder"
      :name="name"
      title=""
      :min="min"
      :max="max"
      :disabled="disabled"
      :model-value="modelValue"
      @change="(value) => $emit('update:modelValue', value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    placeholder: String,
    inputClassName: {
      type: String,
      default: '',
    },
    labelText: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" src="../../styles/components/ui/inputNumber.scss" scoped></style>