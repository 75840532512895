<script setup>
import VPhotogrametryParams from '@/components/params/components/VPhotogrametryParams';
import VSimpleFlightParams from '@/components/params/components/VSimpleFlightParams';

import { useStore } from 'vuex';
import { computed } from 'vue';
import { VSideBar } from '@/components/ui';

const store = useStore();
const { isPhotogrammetry } = defineProps(['isPhotogrammetry']);
const missionProfile = computed(() => store.state.params.missionProfile);
const isSimpleFlight = computed(() => true); //store.state.params.isSimpleFlight);
</script>

<template>
  <VSideBar v-if="missionProfile && !isPhotogrammetry" direction="vertical" :without-header="true">
    <template #content>
      <div>
        <VPhotogrametryParams v-if="!isSimpleFlight" />
        <VSimpleFlightParams v-else />
      </div>
    </template>
  </VSideBar>
</template>

<style lang="scss" scoped></style>