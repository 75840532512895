import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import useFileList from '@/hooks/useFileList';
import { KmzBuilder } from '@/lib/kmz-builder';
import { attachIconsToGeoJSONs } from '@/utils/hooks-utils';

/***
 * @for optimization
 * @param fields ['rows'] or ['rows', 'list'];
 * @param removeExcluded flag works only  when field = ['rows'], use it for removing features & featuresCollection that are blacklisted
 * @returns {{rows: ComputedRef<*[]>, list: ComputedRef<[...*[], {features: FlatArray<*[], number>[], id: (string), type: string}]|*[]>}}
 */
export default function useExport(fields = ['rows'], removeExcluded = false) {
  const store = useStore();
  const excludeIDS = computed({
    get: () => store.state.exports.geoJsonGroupIDsBlackList,
    set: (value) => store.commit('exports/setGeoJsonGroupBlackList', value),
  });

  const isExportModalVisible = computed({
    get: () => store.state.exports.isExportsModalVisible,
    set: (value) => store.commit('exports/setExportsModalVisible', value),
  });

  const setVisibleExportModal = (visible) => {
    isExportModalVisible.value = visible;
  };

  const excludedUUIDs = computed({
    get: () => store.state.exports.featureUUIDsBlackList,
    set: (value) => store.commit('exports/setUUIDsBlackList', value),
  });

  const onExportClickClose = () => setVisibleExportModal(false);

  const fileList = useFileList(fields, {
    blackListConfiguration: {
      uuidModel: excludedUUIDs,
      idModel: excludeIDS,
    },
    isImport: false,
    removeExcluded,
    isExportModalVisible,
    setVisibleExportModal,
  });

  const exportTo3DMap = async () => {
    let geoJSONs = await attachIconsToGeoJSONs(fileList.list.value, true);
    const list_of_KMLs = await KmzBuilder.geoJSONsToKMLs(geoJSONs);
    return KmzBuilder.downloadKMLsAsKMZ({ 
      kmls: list_of_KMLs, 
      fileName: store.state.params.name, 
      returnResult: true
    });
  }

  const onExportClickSuccess = async () => {
    const geoJSONs = await attachIconsToGeoJSONs(fileList.list.value);
    const list_of_KMLs = await KmzBuilder.geoJSONsToKMLs(geoJSONs);
    await KmzBuilder.downloadKMLsAsKMZ({ kmls: list_of_KMLs, fileName: store.state.params.name });
    setVisibleExportModal(false);
  };

  return {
    ...fileList,
    onExportClickClose,
    onExportClickSuccess,
    exportTo3DMap
  };
}
