<script>
import {useStore} from "vuex";
import {computed, defineComponent, ref, watch} from "vue";
import {calculateOpticalParams, useMappingForSelectedGeoJSONItem, useMappingStoreParams} from "@/utils/helpers";
import {VInputNumber, VModal, VCheckBox, VSelect } from '@/components/ui';
import {ObjectsIcon} from '@/assets/svgs';
import usePolygonParams from "@/hooks/geo-json/usePolygonParams";

export default defineComponent({
  components: { VSelect, VInputNumber, VCheckBox, VModal },
  setup() {
    const store = useStore();
    const selectedFeatureUUID = computed(() => store.state.map.selectedPolygon);
    const selectedProfile = computed(() => store.state.params.missionProfiles.find(({ ID }) => ID === store.state.params.missionProfile));

    const { angleModel,angleMode, scanModeModel, scanModeOptionsList } = usePolygonParams();

    const gsdModeOptionsList = computed(() => store.state.params.gsdModeOptionsList || []);
    const overlapModeOptionsList = computed(() => store.state.params.overlapModeOptionsList || []);

    const { sensorWideSizePx: xSize, sensorNarrowSizePx: ySize } = useMappingStoreParams([
        'sensorWideSizePx',
        'sensorNarrowSizePx'
    ])
    const gsdDefModel = useMappingForSelectedGeoJSONItem('gsdDef', 'gsdDef');
    const gsdMaxModel = useMappingForSelectedGeoJSONItem('gsdMax', 'gsdDef')
    const forwardOverlapDefModel = useMappingForSelectedGeoJSONItem('forwardOverlapDef', 'forwardOverlapDef')
    const forwardOverlapMinModel = useMappingForSelectedGeoJSONItem('forwardOverlapMin', 'forwardOverlapMin');
    const sideOverlapDefModel = useMappingForSelectedGeoJSONItem('sideOverlapDef', 'sideOverlapDef');
    const sideOverlapMinModel = useMappingForSelectedGeoJSONItem('sideOverlapMin', 'sideOverlapMin');
    const extraPhotosDefModel = useMappingForSelectedGeoJSONItem('extraPhotosDef','extraPhotosDef');
    const overshootZoneDefModel = useMappingForSelectedGeoJSONItem('overshootZoneDef', 'overshootZoneDef');
    const altDefModelModel = useMappingForSelectedGeoJSONItem('altDef', 'altDef');
    const gsdModeModel = useMappingForSelectedGeoJSONItem('gsdMode', 'gsdMode')
    const overlapModeModel = useMappingForSelectedGeoJSONItem('overlapMode', 'overlapMode')
    const opticalHeightModel = computed(() => calculateOpticalParams(gsdDefModel.value, ySize.value));
    const opticalWeightModel = computed(() => calculateOpticalParams(gsdDefModel.value, xSize.value));

    const FORM_FIELDS = () => [
      {
        label: 'GSD DEF (cm/px)',
        modelValue: gsdDefModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'GSD MAX (cm/px)',
        modelValue: gsdMaxModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'Forward Overlap DEF (%)',
        modelValue: forwardOverlapDefModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'Forward Overlap Min (%)',
        modelValue: forwardOverlapMinModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'Side Overlap DEF (%)',
        modelValue: sideOverlapDefModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'Side Overlap Min (%)',
        modelValue: sideOverlapMinModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'Scan Angle [deg]',
        modelValue: angleModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'Extra Photos DEF (pcs)',
        modelValue: extraPhotosDefModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'Overshoot (m)',
        modelValue: overshootZoneDefModel,
        viewClass: 'VInputNumber'
      },
      {
        label: 'Const. Altitude DEF',
        modelValue: altDefModelModel,
        viewClass: 'VCheckBox'
      },
      {
        label: 'Scan Mode',
        modelValue:  scanModeModel,
        viewClass: 'VSelect',
        options: scanModeOptionsList
      },
      {
        label: 'GSD Mode',
        modelValue:  gsdModeModel,
        disabled: !(altDefModelModel && altDefModelModel.getter()),
        viewClass: 'VSelect',
        options: gsdModeOptionsList
      },
      {
        label: 'OverlapMode',
        modelValue:  overlapModeModel,
        disabled: !(altDefModelModel && altDefModelModel.getter()),
        viewClass: 'VSelect',
        options: overlapModeOptionsList
      }
    ]

    const fieldList = ref([]);
    const isVisible = computed(() => store.state.params.isAdvancedZoneParamsVisible && selectedProfile.value);
    const onClose = () => store.commit('params/setAdvancedZoneParamsVisible', false);
    const buildFields = () => (fieldList.value = FORM_FIELDS());

    // Recompute fields after
    watch(selectedFeatureUUID, buildFields, { immediate: true });
    watch(altDefModelModel, buildFields);

    return {
      fieldList: fieldList,
      onClose,
      isVisible,
      ObjectsIcon,
      opticalHeightModel,
      opticalWeightModel
    }
  }
});


</script>

<template>
  <VModal
      v-if="isVisible"
      :is-visible="isVisible"
      :title="'Advanced zone params'"
      :title-icon="ObjectsIcon"
      ok-text="Ok"
      close-text="Close"
      size="xl"
      @close="onClose()"
      @success="onClose()"
      @keydown:escape="onClose()"
  >
    <template #body>
      <div class="photometry-advanced-params-form">
        <div class="photometry-advanced-params-form__main">
          <div class="photometry-advanced-params-form__main__item" v-for="field in fieldList" :key="field.modelValue">
            <div class="photometry-advanced-params-form__main__item__label">{{field.label}}</div>
            <component :is="field.viewClass" :disabled="field.disabled" :options="field.options" size="m" v-model="field.modelValue"></component>
          </div>
        </div>
      </div>
      <div class="photometry-advanced-params-form__main photometry-advanced-params-form__footer">
        <div class="photometry-advanced-params-form__main__item">
          <div class="photometry-advanced-params-form__main__item__label">Optical Height [m]</div>
          <VInputNumber disabled v-model="opticalHeightModel"/>
        </div>
        <div class="photometry-advanced-params-form__main__item">
          <div class="photometry-advanced-params-form__main__item__label">Optical Weight [m]</div>
          <VInputNumber disabled v-model="opticalWeightModel"/>
        </div>
      </div>
    </template>
  </VModal>
</template>

<style lang="scss" scoped>
.photometry-advanced-params-form {
  &__main {
    max-height: 400px;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-around;
    align-items: center;
    &__item {
      width: 45%;
      display: flex;
      margin-top: 10px;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      &__label {
        color: var(--black-400);
        font-size: 14px;
      }
    }
    &__item:last-child {
      position: relative;
      right: -25%;
    }
  }
  &__footer {
    .photometry-advanced-params-form__main__item:last-child {
      position: static;
    }
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 15px;
    border-top: 1px solid var(--black-700);
  }
}
</style>