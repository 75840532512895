import { useStore } from 'vuex';
import { computed } from 'vue';
import { get } from 'lodash';
import {
  getCenterOfPolygon,
  getVectorGeoJSONbyPointCoords,
  transformCoordinatesToVectorFeature,
  transformGeoJsonCoordinatesToNumeric,
} from '@/utils/mapUtils';
import { GEO_JSON_IDS as IDS_CONST } from '@/config/map-config';
import { useMappingForSelectedGeoJSONItem } from '@/utils/helpers';
import { convertDegree } from '@/utils/mapUtils';

export default function usePolygonParams() {
  const store = useStore();
  const selectedPolygon = computed(() => store.state.map.selectedPolygon);
  const vector = computed(() => store.getters['map/currentVector']);
  const selectedFeature = computed(() => store.getters['map/getSelectedFeature']);
  const scanModeOptionsList = computed(() => store.state.params.scanModeOptionsList);
  const isPhotogrammetry = computed(() => store.getters['params/isPhotogrammetry']);

  const scanModeModel = useMappingForSelectedGeoJSONItem('scanMode', 'scanMode');
  const lineSpacingModel = useMappingForSelectedGeoJSONItem('lineSpacing', 'LineSpacing');
  const flightAltitudeAGLModel = useMappingForSelectedGeoJSONItem(
    'flightAltitudeAGL',
    'H_AGL_Flight'
  );
  const constantAltitudeModel = useMappingForSelectedGeoJSONItem(
    'constantAltitude',
    'constantAltitude'
  );
  const overshootLength = useMappingForSelectedGeoJSONItem(
    'overshootLength',
    'L_OvershootLine_Zone'
  );
  const phOvershootLength = useMappingForSelectedGeoJSONItem('overshootZoneDef', 'overshootZoneDef')

  const angleModel = computed({
    get: () => {
      if (!selectedPolygon.value || !vector.value) return undefined;
      const vectorCoordinates = get(vector.value, 'geometry.coordinates');
      if (!vectorCoordinates) return undefined;
      return convertDegree(
        Number(transformGeoJsonCoordinatesToNumeric(vectorCoordinates).toFixed(0))
      );
    },
    set: (value) => {
      const { lat, lng } = getCenterOfPolygon(selectedFeature.value?.geometry);
      const startPoint = [lng, lat];
      const vectorFeatures = vector.value;
      const vectorCoordinates = get(vectorFeatures, 'geometry.coordinates');
      if (
        (startPoint && startPoint.every((e) => e)) ||
        (vectorCoordinates && vectorCoordinates.every((e) => e))
      ) {
        const targetPointCoordinates =
          vectorCoordinates && vectorCoordinates.every((e) => e)
            ? vectorCoordinates[0]
            : startPoint;
        const featureCoords = getVectorGeoJSONbyPointCoords(targetPointCoordinates, value);
        const feature = transformCoordinatesToVectorFeature(featureCoords);
        store.commit('map/setVector', feature);
      }
    },
  });

  const maxAttitude = computed(() => store.getters['map/getCppAttitude']);

  const zoneScanningModel = computed({
    get: () => (selectedPolygon.value ? 1 : null),
    set: (val) => {
      console.log(val);
    },
  });
  const zoneScanningOptions = [{ label: 'To wind', value: 1 }];

  const onClearAngle = () => store.commit('map/clearVectorIfExit');

  return {
    angleModel,
    maxAttitude,
    zoneScanningModel,
    zoneScanningOptions,
    onClearAngle,
    showClear: vector,
    lineSpacingModel,
    flightAltitudeAGLModel,
    constantAltitudeModel,
    overshootLength,
    isPhotogrammetry,
    phOvershootLength,
    scanModeModel,
    scanModeOptionsList,
  };
}
