import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-easybutton';
import 'leaflet-simple-map-screenshoter';
import '../patches';
import '../plugins/leaflet-betterscale/L.Control.BetterScale.js';
import '../plugins/leaflet-betterscale/L.Control.BetterScale.css';
import '../plugins/leaflet.coordinates/Leaflet.Coordinates-0.1.5.css';
import '../plugins/leaflet.coordinates/Leaflet.Coordinates-0.1.5.src.js';
import { v4 as uuidv4 } from 'uuid';
import * as config from '@/config/map-config';
import { BASE_LAYERS } from '@/config/map-config';
import service from '@/services/service';
import 'leaflet.polylinemeasure';

const options = {
  position: 'topright',            // Position to show the control. Values: 'topright', 'topleft', 'bottomright', 'bottomleft'
  unit: 'metres',                 // Show imperial or metric distances. Values: 'metres', 'landmiles', 'nauticalmiles'
  clearMeasurementsOnStop: true,  // Clear all the measurements when the control is unselected
  showBearings: true,            // Whether bearings are displayed within the tooltips
  bearingTextIn: 'In' ,            // language dependend label for inbound bearings
  bearingTextOut: 'Out',          // language dependend label for outbound bearings
  tooltipTextFinish: 'Click "Esc" to <b>finish line</b> Or Double click "Esc" to clear measurements<br>',
  tooltipTextDelete: 'Press SHIFT-key and click to <b>delete point</b>',
  tooltipTextMove: 'Click and drag to <b>move point</b><br>',
  tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
  tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
  // language dependend labels for point's tooltips
  measureControlTitleOn: 'Start Measure',   // Title for the control going to be switched on
  measureControlTitleOff: 'Stop Measure', // Title for the control going to be switched off
  measureControlLabel: '&#8614;', // Label of the Measure control (maybe a unicode symbol)
  measureControlClasses: [],      // Classes to apply to the Measure control
  showClearControl: false,        // Show a control to clear all the measurements
  clearControlTitle: 'Clear Measurements', // Title text to show on the clear measurements control button
  clearControlLabel: '&times',    // Label of the Clear control (maybe a unicode symbol)
  clearControlClasses: [],        // Classes to apply to clear control button
  showUnitControl: false,         // Show a control to change the units of measurements
  distanceShowSameUnit: false,    // Keep same unit in tooltips in case of distance less then 1 km/mi/nm
  unitControlTitle: {             // Title texts to show on the Unit Control button
    text: 'Change Units',
    metres: 'metres',
    landmiles: 'land miles',
    nauticalmiles: 'nautical miles'
  },
  unitControlLabel: {             // Unit symbols to show in the Unit Control button and measurement labels
    metres: 'm',
    kilometres: 'km',
    feet: 'ft',
    landmiles: 'mi',
    nauticalmiles: 'nm'
  },
  tempLine: {                     // Styling settings for the temporary dashed line
    color: '#000',              // Dashed line color
    weight: 2                   // Dashed line weight
  },
  fixedLine: {                    // Styling for the solid line
    color: '#000',              // Solid line color
    weight: 2                   // Solid line weight
  },
  startCircle: {                  // Style settings for circle marker indicating the starting point of the polyline
    color: '#000',              // Color of the border of the circle
    weight: 1,                  // Weight of the circle
    fillColor: '#0f0',          // Fill color of the circle
    fillOpacity: 1,             // Fill opacity of the circle
    radius: 3                   // Radius of the circle
  },
  intermedCircle: {               // Style settings for all circle markers between startCircle and endCircle
    color: '#000',              // Color of the border of the circle
    weight: 1,                  // Weight of the circle
    fillColor: '#ff0',          // Fill color of the circle
    fillOpacity: 1,             // Fill opacity of the circle
    radius: 3                   // Radius of the circle
  },
  currentCircle: {                // Style settings for circle marker indicating the latest point of the polyline during drawing a line
    color: '#000',              // Color of the border of the circle
    weight: 1,                  // Weight of the circle
    fillColor: '#f0f',          // Fill color of the circle
    fillOpacity: 1,             // Fill opacity of the circle
    radius: 3                   // Radius of the circle
  },
  endCircle: {                    // Style settings for circle marker indicating the last point of the polyline
    color: '#000',              // Color of the border of the circle
    weight: 1,                  // Weight of the circle
    fillColor: '#f00',          // Fill color of the circle
    fillOpacity: 1,             // Fill opacity of the circle
    radius: 3                   // Radius of the circle
  },
};
export default {
  data: () => ({
    map: null,
    defaultViewBounds: L.latLngBounds(
      L.latLng(44.372723, 21.466028),
      L.latLng(52.483808, 40.074808)
    ).getCenter(),
    defaultZoom: 6,
  }),
  mounted() {
    const lastZoomValue = localStorage.getItem('lastZoomValue');
    const lastBounds = localStorage.getItem('lastCoordinatesViewValue');
    const lastBaseLayerID = localStorage.getItem('lastBaseLayerID');

    localStorage.removeItem('lastZoomValue');
    localStorage.removeItem('lastCoordinatesViewValue');
    const map = L.map('map', {
      zoomAnimation: true,
      zoomControl: false,
      fadeAnimation: true,
      attributionControl: false,

    }).setView(
      (lastBounds && JSON.parse(lastBounds)) || this.defaultViewBounds,
      (lastZoomValue && Number(lastZoomValue)) || this.defaultZoom
    );

    L.control.zoom({ position: 'bottomright' }).addTo(map);
    L.control.betterscale({ imperial: false, metric: true }).addTo(map);
    L.control
      .coordinates({
        enableUserInput: false,
        customLabelFcn: this.handleLatLngChange,
      })
      .addTo(map);
    const layers = BASE_LAYERS.reduce((acc, layer) => {
      const baseLayer = L.tileLayer(layer.url, layer);
      if ((layer.isDefault && !lastBaseLayerID) || lastBaseLayerID == layer.ID) {
        baseLayer.addTo(map);
      }
      return { ...acc, [layer.label]: baseLayer };
    }, {});

    L.control.layers(layers).addTo(map);

    map
      .on('click', (e) => {
        Object.values(this.editableLayer?._layers || {}).forEach((l) => l.pm.disable());
      })
      .on('baselayerchange', function (e) {
        localStorage.setItem('lastBaseLayerID', e?.layer?.options?.ID);
      });

    // connecting measurements lib;
    const polylineMeasure = L.control.polylineMeasure(options).addTo(map);
    window.polylineMeasureControl = polylineMeasure;

    const open3DMap = () => this.$store.commit('toggleMap3DMode')

    L.Control.Map3DBtn = L.Control.extend({
      options: { position: 'topright' },
      onAdd: () => {
        var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        var button = L.DomUtil.create('a', 'leaflet-control-button', container);
        L.DomEvent.disableClickPropagation(button);
        L.DomEvent.on(button, 'click', open3DMap)
        button.title = "Open 3D map";
        button.style.cssText = 'font-size: large; font-weight: bold; cursor: pointer;'
        button.innerText = "3D";
        return container;
      },
    });

    new L.Control.Map3DBtn().addTo(map);

    // listening map edit toggle events;
    Object.values(config.MAP_EVENTS.TOGGLES).forEach((event) => {
      map.on(event, () => polylineMeasure._measuring && polylineMeasure._toggleMeasure());
    });
    this.map = map;
  },
  methods: {
    async handleLatLngChange({ lat, lng }) {
      try {
        let height = await service.getHeightConstant([lng, lat, 0]);
        height = height || height === 0 ? `${height}m` : 'No data';
        return `<strong>${lat.toFixed(6)} ${lng.toFixed(6)} ${height}</strong>`;
      } catch (error) {
        return '---';
      }
    },
    startDraw(shape) {
      this.map.pm.disableDraw();
      this.map.pm.enableDraw(shape);
    },
    stopDrawing(needToSave = false) {
      this.map.pm.disableDraw();
    },

    drawMarker_() {
      this.map.pm.disableDraw();
      this.map.pm.enableDraw('Marker');

      this.map.once('pm:create', async (e) => {
        if (e.shape === 'Marker') {
          const markerCoordinates = e.layer.getLatLng();
          this.$emit('add-fm-point', {
            latitude: markerCoordinates.lat,
            longitude: markerCoordinates.lng,
          });

          e.layer.removeFrom(this?.map);
          this.map.pm.disableDraw();
        }
      });
    },

    handleKeyDown(event) {
      if (event.key === 'Escape') {
        this.areaSelectionMode = false;
        this.map.pm.disableDraw();
      }
    },

    areNumbersApproximatelyEqual(number1, number2, tolerance) {
      return Math.abs(number1 - number2) <= tolerance;
    },

    moveTextMarker(id, lat, lng) {
      this.$store.commit('SET_DATA', {
        key: 'additionalGeometry',
        value: this.currentGeometry.map((obj) => {
          const newObj = { ...obj };
          if (obj.id === id) {
            newObj.lat = lat;
            newObj.lng = lng;
          }

          return newObj;
        }),
      });
    },

    addLayer(layer) {
      layer.addTo(toRaw(this.map));
      this.map.invalidateSize();
    },

    removeLayer(layer) {
      layer.removeFrom(this.map);
    },

    addNonGroupLayers(sourceLayer, targetGroup, props = null) {
      if (sourceLayer instanceof L.LayerGroup) {
        sourceLayer.eachLayer((layer) => this.addNonGroupLayers(layer, targetGroup, props));
      } else {
        if (props) sourceLayer.properties = props;
        if (sourceLayer?.feature?.properties?.color)
          sourceLayer?.setStyle?.({ color: sourceLayer.feature.properties.color });
        targetGroup.addLayer(sourceLayer);
      }

      return targetGroup;
    },

    toggleMarkerTooltips() {
      this.eventsLayer2.eachLayer((marker) => {
        if (!this.areEventNumbers) {
          const tooltipContent = this.tooltipContents[marker._leaflet_id];
          this.tooltipOptions.className = /models\/geo\/map-icons\/default-icon.png/g.test(
            marker.options.icon.options.iconUrl
          )
            ? 'marker-tooltip-default'
            : 'marker-tooltip';
          marker.bindTooltip(tooltipContent, this.tooltipOptions);
        } else {
          marker.unbindTooltip();
        }
      });

      this.areEventNumbers = !this.areEventNumbers;
    },

    handleVectorDrawing(e) {
      if (!this.isVectorDrawing) return true;

      const { features } = this.editableLayer.toGeoJSON();
      e?.layer?.removeFrom(window.map);
      e.target.removeLayer(e.layer);

      const defaultGeoJSONChanges = features?.filter(
        (e) => e?.id === config.GEO_JSON_IDS.default || !e.id
      );
      const vector = defaultGeoJSONChanges[defaultGeoJSONChanges.length - 1];

      const vectorToSave = {
        type: 'FeatureCollection',
        style: {
          weight: 5,
          dashArray: '10',
        },
        id: config.GEO_JSON_IDS.vector,
        features: [
          ...(vector
            ? [
                {
                  ...vector,
                  id: config.GEO_JSON_IDS.vector,
                  geometry: {
                    ...vector.geometry,
                    coordinates: vector?.geometry?.coordinates?.splice(0, 2),
                  },
                },
              ]
            : []),
        ],
      };
      return this.$store.commit('map/setVector', vectorToSave); //this.$emit('onFinishVectorDrawing', vectorToSave);
    },

    onGeoJSOMSave(e) {
      const { features: geoJsonFromMap } = this.editableLayer.toGeoJSON(),
        geoJson = this.geoJson;

      const data = Object.groupBy(geoJsonFromMap, ({ id }) => id);
      if (!data[config.GEO_JSON_IDS.default] || data[undefined]?.length) {
        data[config.GEO_JSON_IDS.default] = (data[config.GEO_JSON_IDS.default] || []).concat(
          data[undefined] || []
        );
        data[config.GEO_JSON_IDS.default] = data[config.GEO_JSON_IDS.default].filter((e) =>
          ['LineString', 'Polygon'].includes(e?.geometry?.type) || e?.properties?.isTargetPoint
        );
        delete data[undefined];
      }

      const isSelected = this.showPolygonsByID.includes(config.GEO_JSON_IDS.default);
      if (!isSelected) return;

      const geoJsonExist = geoJson.find(({ id }) => !id || id === config.GEO_JSON_IDS.default);

      const geoJsonToSave = {
        ...geoJsonExist,
        id: config.GEO_JSON_IDS.default,
        features: data[config.GEO_JSON_IDS.default]?.map((e, i) => ({
          ...e,
          uuid: e.uuid || uuidv4(),
          id: config.GEO_JSON_IDS.default,
        })),
      };
      this.$store.commit('map/setGeoJson', geoJsonToSave);
    },

    addEditableLayer() {
      if (!this.editableLayer) {
        const l = L.layerGroup([]);
        this.editableLayer = l; //?.layer
      }
    },
  },
};
