<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { VSideBar } from '@/components/ui';
import VWeatherParams from '@/components/params/VWeatherParams';
import VMissionParams from '@/components/params/VMissionParams';
import VFlightParams from '@/components/params/VFlightParams';

import { FlagIcon, EditIcon, PlainIcon, SettingIcon } from '@/assets/svgs';
import VMissionTypeSelect from '@/components/params/components/VMissionTypeSelect';
import VButton from '@/components/ui/VButton.vue';

// ToDo: if we are going to add localisation using i18n we should add path here and i18n(texts..) in template
const texts = {
  params: {
    title: 'Wind and weather',
    icon: FlagIcon,
  },
  missionParams: {
    title: 'Mission parameters',
    icon: EditIcon,
  },
  flightParams: {
    title: 'Flight Parameters',
    icon: PlainIcon,
  },
};

const store = useStore();
const missionProfiles = computed(() => store.state.params.missionProfiles);
const missionProfile = computed(() => store.state.params.missionProfile);
const selectedProfile = computed(() =>
  missionProfiles.value.find((e) => e.ID === missionProfile.value)
);

const isPhotogrammetry = computed(() => store.getters['params/isPhotogrammetry']);
const onPhotogrammetryParamsClick = () => store.commit('params/setIsPhotogrammetryGlobalParamsVisible', true)
</script>
<template>
  <div class="params">
    <div class="params__item">
      <VSideBar direction="vertical">
        <template #header>
          <div class="params__item__header">
            <img class="params__item__header__icon" :src="texts.params.icon" alt="params" />
            <span class="params__item__header__title">{{ texts.params.title }}</span>
          </div>
        </template>
        <template #content>
          <VWeatherParams />
        </template>
      </VSideBar>
      <VSideBar direction="vertical">
        <template #header>
          <div class="params__item__header">
            <img class="params__item__header__icon" :src="texts.missionParams.icon" alt="params" />
            <span class="params__item__header__title">{{ texts.missionParams.title }}</span>
          </div>
        </template>
        <template #content>
          <VMissionTypeSelect />
        </template>
      </VSideBar>
      <div v-if="isPhotogrammetry" class="photometry">
        <VButton @click="onPhotogrammetryParamsClick" title="Photogrammetry parameters" size="m">
          <template #icon>
            <img :src="SettingIcon" alt="setting" />
          </template>
        </VButton>
      </div>
      <VMissionParams :is-photogrammetry="isPhotogrammetry" />
      <VSideBar direction="vertical">
        <template #header>
          <div class="params__item__header">
            <img class="params__item__header__icon" :src="texts.flightParams.icon" alt="params" />
            <span class="params__item__header__title">{{ texts.flightParams.title }}</span>
          </div>
        </template>
        <template #content>
          <VFlightParams />
        </template>
      </VSideBar>
    </div>
  </div>
</template>

<style lang="scss" src="@/styles/components/params/params.scss" scoped></style>