<template>
  <VModal 
    title="Preview 3D map" 
    :is-visible="isVisible" 
    :customContainerClass="['cesiumModalContainer']" 
    :visibleSaveBtn="false"
    @close="handleClose"
  >
    <template #body>
      <div id="cesiumContainer"></div>
    </template>
  </VModal>
</template>

<script>
import { VModal } from '@/components/ui';
export default {
  name: 'FlightPlannerMap3D',
  components: { VModal },
  props: ['kmz', 'isVisible'],
  watch: {
    kmz(newVal) {
      this.addKmzDataSource(newVal)
    }
  },
  async mounted() {
    this.viewer = new Cesium.Viewer('cesiumContainer', {
      animation: false,
      terrain: Cesium.Terrain.fromWorldTerrain(),
      baseLayerPicker: true,
      navigationHelpButton: false,
      sceneModePicker: false,
      homeButton: true,
      geocoder: true,
      fullscreenButton: false,
      timeline: false,
      scene3DOnly: true,
      creditContainer: document.createElement("none"),
    });

    // this.viewer.scene.setTerrain(new Cesium.Terrain(Cesium.CesiumTerrainProvider.fromUrl(`/terrain`)));

    this.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
      (e) => {
        if (this.kmzDataSource) {
          e.cancel = true;
          this.viewer.flyTo(this.kmzDataSource, { duration: 0 });
        }
      });
  },
  methods: {
    handleClose() {
      this.$store.commit('toggleMap3DMode')
    },
    addKmzDataSource(kmz) {
      try {
        if (this.kmzDataSource) {
          this.viewer.dataSources.remove(this.kmzDataSource)
        }
        this.kmzDataSource = new Cesium.KmlDataSource()
        this.kmzDataSource.load(kmz, { clampToGround: true })
        this.viewer.dataSources.add(this.kmzDataSource)
        this.viewer.flyTo(this.kmzDataSource, { duration: 0 });
      } catch (error) {
        console.log(error)
      }
    }
  }
};
</script>
<style>
.cesiumModalContainer {
  width: 80% !important;
}

#cesiumContainer {
  width: 100%;
  height: 100%;
  outline-style: none;
}
</style>