import { defaultGeoJsonItemDescription, styleNamespace, stylesConfig } from './constants';
import {
  DEFAULT_OPTIONS as options,
  ROOT_KMZ_FILENAME as rootKmzFile,
} from '@/config/export-config';
import { get } from 'lodash';
import toKml from 'tokml';
import JSZip from 'jszip';
import { saveAs } from 'save-as';
import { v4 } from 'uuid';
import {GEO_JSON_IDS as IDS_CONST} from '@/config/map-config'
// All other params will be removed from KML/KMZ;
const DATA_TAG_NAMESPACING_WHITELIST = ['name', 'description'];

const addHeightConstantToCoordinates = (coordinates, constanta) => {
  return Array.isArray(coordinates) && Number(coordinates[0])
    ? [coordinates[0], coordinates[1], coordinates[2] ? coordinates[2] + constanta : constanta]
    : coordinates.map((_) => addHeightConstantToCoordinates(_, constanta));
};

function propertiesToClassicFormat(properties) {
  return {
    ...Object.keys(styleNamespace).reduce((acc, key) => {
      if (key === 'color' && properties.color)
        return {
          ...acc,
          stroke: properties.color,
          fill: properties.color,
          markerColor: properties.color,
          'stroke-opacity': 1,
        };
      return properties && properties[key]
        ? { ...acc, [styleNamespace[key]]: properties[key] }
        : acc;
    }, {}),
  };
}
/***
 *
 * @param features
 * @param addHeightsConstant <Number | null>
 * @returns {{features, type: string}}
 */

function toClassicFormat(
  { features: f, properties, id, style, styles },
  addHeightsConstant = null
) {
  const itemId = id === IDS_CONST.default && properties?.isExcluded ? IDS_CONST.custom : id;

  const globalProperties = Object.assign({}, stylesConfig[itemId]);

  if(!stylesConfig[itemId])
    f.map(({ properties, style }) => Object.assign(globalProperties, properties || {}, style || {}));

  const features = f.map((feature) => ({
    geometry: {
      coordinates: addHeightsConstant
        ? addHeightConstantToCoordinates(feature?.geometry?.coordinates, addHeightsConstant)
        : feature?.geometry?.coordinates, //.map(coords => addHeightsConstant ? addHeightConstantToCoordinates(coords, addHeightsConstant) : coords),
      type: feature?.geometry?.type,
    },
    properties: propertiesToClassicFormat({
      ...globalProperties,
      icon: feature?.properties?.icon,
      name: feature.properties?.name || 'Untitled',
    }),
    type: 'Feature',
  }));

  return {
    type: 'FeatureCollection',
    features,
  };
}
function geoJSONsToKMLs(geoJSONs) {
  // TODO: add takeoff GroundAltitudeAMSL for all path points altitudes
  return Promise.all(
    geoJSONs.map(async (geoJSON) => {
      const kmlParserOptions = { ...options };
      if (get(geoJSON, 'title')) {
        Object.assign(kmlParserOptions, {
          name: get(geoJSON, 'title'),
          description: get(geoJSON, 'title'),
        });
      } else {
        Object.assign(kmlParserOptions, { name: 'Untitled', description: 'Untitled' });
      }
      const takeoffElevation =
        geoJSON?.features[0]?.properties?.PointProps?.[0]?.GroundAltitudeAMSL ?? 0;

      const kml = toKml(toClassicFormat(geoJSON, takeoffElevation), kmlParserOptions);
      return {
        kml: takeoffElevation
          ? kml.replace(/<coordinates>/g, '<altitudeMode>absolute</altitudeMode><coordinates>')
          : kml,
        fileName: `${kmlParserOptions.name}.kml`,
      };
    })
  );
}

function extractKmlContent(kmlContent) {
  const dom = new DOMParser().parseFromString(kmlContent, 'application/xml');
  const content = dom.getElementsByTagName('Document');
  const placemarks = content.item(0).getElementsByTagName('Placemark');
  const styleValues = {};

  for (const geoItem of placemarks) {
    const dataNameNode = geoItem.getElementsByTagName('Data');
    const naming_node = Array.from(dataNameNode).find((e) => e.getAttribute('name') === 'name');
    const iconUrlNodeType = Array.from(dataNameNode).find(
      (e) => e.getAttribute('name') === 'iconUrl'
    );
    const styleContainer = geoItem.parentNode;
    let iconStyleRef = null;
    const isPoint = Array.from(geoItem.getElementsByTagName('Point')).length;

    if (isPoint && iconUrlNodeType && iconUrlNodeType?.textContent) {
      const iconUrlValue = iconUrlNodeType.textContent;
      const styleKeys = Object.keys(styleValues).find(
        (key) => styleValues[key] === iconUrlNodeType?.textContent
      );
      iconStyleRef = styleKeys || v4();

      if (!styleKeys) {
        const styleNewNode = new DOMParser().parseFromString(
          `
                    <custom_styles_withoid id="${iconStyleRef}">
                        <IconStyle>
                          <Icon>
                            <href>${iconUrlValue}</href>
                          </Icon>
                          <hotSpot x="0.5" y="0.5" xunits="fraction" yunits="fraction">
                        </IconStyle>            
                    </custom_styles_withoid>
            `,
          'text/html'
        );

        const {
          body: { children: styleNodes },
        } = styleNewNode;
        Array.from(styleNodes).map((node) =>
          styleContainer.insertBefore(node, styleContainer.children.item(0))
        );
        styleValues[iconStyleRef] = iconUrlValue;
      }
    }

    if (naming_node) {
      const text = naming_node.textContent;
      const titleNode = new DOMParser().parseFromString(
        `<name>${text}</name><description>${defaultGeoJsonItemDescription}</description>
                        ${iconStyleRef ? `<styleUrl>#${iconStyleRef}</styleUrl>` : ''}`,
        'text/html'
      );
      Array.from(titleNode.body.children).forEach((item) => {
        geoItem.append(item);
      });
    }
  }

  Array.from(dom.getElementsByTagName('Data'))
    .filter((node) => !DATA_TAG_NAMESPACING_WHITELIST.includes(node.getAttribute('name')))
    .forEach((node) => node.remove());

  return dom
    .getElementsByTagName('Document')
    .item(0)
    .innerHTML.replace(/<name xmlns="http:\/\/www.w3.org\/1999\/xhtml">/g, '<name>')
    .replace(/<description xmlns="http:\/\/www.w3.org\/1999\/xhtml">/g, '<description>')
    .replace(/styleurl xmlns="http:\/\/www.w3.org\/1999\/xhtml"/g, 'styleUrl')
    .replace(/styleurl/g, 'styleUrl')
    .replace(/custom_styles_withoid xmlns="http:\/\/www.w3.org\/1999\/xhtml"/g, 'Style')
    .replace(/custom_styles_withoid/g, 'Style')
    .replace(/<icon/g, '<Icon')
    .replace(/<\/icon/g, '</Icon')
    .replace(/Iconstyle/g, 'IconStyle')
    .replace(/hotspot/g, 'hotSpot');
}
async function downloadKMLsAsKMZ({ kmls, fileName = 'mission', returnResult = false }) {
  const zip = new JSZip();

  let kmzContent = `<?xml version="1.0" encoding="UTF-8"?>
                        <kml xmlns="http://www.opengis.net/kml/2.2">
                        <Document>
                        <name>${fileName}</name>
                            ${kmls
                              .map(
                                (e, index) => ` 
                                   <Folder>
                                    <name>${e.fileName || 'Untitled'}</name>
                                    <visibility>1</visibility>
                                    <open>1</open>
                                     ${extractKmlContent(e.kml)}
                                   </Folder>
                                  `
                              )
                              .join(' ')}
                       </Document>
                       </kml>`;
  zip.file(rootKmzFile, kmzContent);

  const content = await zip.generateAsync({ type: 'blob' });

  if(returnResult) return content

  saveAs(content, `${fileName || 'UnsavedMission'}.kmz`);
}

const kmzExportTools = {
  addHeightConstantToCoordinates,
  downloadKMLsAsKMZ,
  geoJSONsToKMLs,
};

export default kmzExportTools;
