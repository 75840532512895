import { GEO_JSON_IDS as IDS_CONST, START_POINT_NAME, END_POINT_NAME, TARGET_POINT_NAME } from '@/config/map-config';
import downScaler from '@/utils/downscaler';
import { v4 } from 'uuid';

export async function attachIconsToGeoJSONs(geoJSONsCollections, asLocalUrl = false) {
  return await Promise.all(
    geoJSONsCollections.map(async (geoJson) => {
      const features = await Promise.all(
        geoJson.features.map(async (feature) => {
          const isPoint = feature?.geometry?.type === 'Point';
          const isTargetPoint = isPoint && feature?.properties.isTargetPoint;

          if (!isPoint) return feature;
          const pointType = (isTargetPoint && TARGET_POINT_NAME) || feature?.properties?.type;

          let localUrl = null;

          switch (pointType?.toLowerCase()) {
            case START_POINT_NAME?.toLowerCase():
              localUrl = '/map/icons/start.png';
              break;
            case END_POINT_NAME?.toLowerCase():
              localUrl = '/map/icons/finish.png';
              break;
            case TARGET_POINT_NAME?.toLowerCase():
              localUrl = '/map/icons/orange-marker.svg';
              break;

            default:
              // toDo:
              //  if we require use numbering of points make sense to do converting from HTML to PNG;
              //  this lib can be suitable: https://www.npmjs.com/package/html-to-image;
              localUrl = '/map/icons/yellow-marker.svg';
          }

          const iconInBase64 = await downScaler(localUrl, [12, 17]);

          return {
            ...feature,
            properties: {
              ...(feature?.properties || {}),
              icon: asLocalUrl ? localUrl: iconInBase64,
            },
          };
        })
      );
      return Object.assign({}, geoJson, { features });
    })
  );
}
