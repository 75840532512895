<template>
  <div @click="$emit('click')" class="vbutton" :style="size === 'm' ? 'width: 200px' : 'width: 163px'">
    <div class="icon">
      <slot name="icon" />
    </div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 's',
    },
  },
};
</script>

<style lang="scss" scoped>
.vbutton {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: 22px;
  background: #2d2c2c;
  border-radius: 8px;
  color: #dadae8;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.06px;
  border: 1px solid #7d7d91;
  &:hover,
  &:focus,
  &:focus-within {
    border-color: #fff;
  }
  .icon {
    display: flex;
    margin-right: 4px;
  }
}
</style>